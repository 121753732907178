import React, { useEffect, useState } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import paymentQR from "../images/paymentQR.png";
import "./InterviewSchedule.css";

function InterviewSchedule() {
  const navigate = useNavigate();
  const [InternId, setInternId] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [plan, setPlan] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      setInternId(decodedToken.internid);
    }
  }, []);

  useEffect(() => {
    if (InternId !== "") {
      getuserdata(InternId);
    }
  });

  const getuserdata = async (InternId) => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/InternshipApplication/getUserData.php/getUserdata",
        { InternId: InternId }
      );
      setUserName(response.data.Firstname + " " + response.data.Lastname);
      setEmail(response.data.Email);
      setPhone(response.data.Phone);
      setPlan(response.data.SelectedPlan);
      if (response.data.Status === "Selected") {
        navigate("/User-Dashboard");
      } else if (response.data.Status !== "Pending") {
        navigate("/Apply-For-Internship");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  // Razorpay integration
  // useEffect(() => {
  //   if (InternId !== "") {
  //     fetchPaymentDetails(InternId);
  //   }
  // });

  // const loadScript = (src) => {
  //   return new Promise((resolve) => {
  //     const script = document.createElement("script");
  //     script.src = src;
  //     script.onload = () => {
  //       resolve(true);
  //     };
  //     script.onerror = () => {
  //       resolve(false);
  //     };
  //     document.body.appendChild(script);
  //   });
  // };
  // const handlePayment = async () => {
  //   const res = await loadScript(
  //     "https://checkout.razorpay.com/v1/checkout.js"
  //   );
  //   if (!res) {
  //     alert("Razorpay SDK failed to load. Are you online?");
  //     return;
  //   }
  //   const options = {
  //     key: "rzp_test_WZkIC9sNpsyUfe",
  //     amount: "100",
  //     currency: "INR",
  //     name: "PracticoIT",
  //     description: "Thanks For Chosing PracticoIT",
  //     image: logo,
  //     theme: {
  //       color: "#242f9b",
  //     },
  //     prefill: {
  //       name: userName,
  //       email: email,
  //       contact: phone,
  //     },
  //     handler: async (res) => {
  //       if (res.razorpay_payment_id) {
  //         const response = await axios.post(
  //           "https://practicoit.in/Server/UserDashboard/PaymentGateway/paymentDetails.php",
  //           {
  //             internId: InternId,
  //             name: userName,
  //             email: email,
  //             contact: phone,
  //             paymentId: res.razorpay_payment_id,
  //           }
  //         );
  //         if (response.data.status === "success") {
  //           fetchPaymentDetails(InternId);
  //         }
  //       }
  //     },
  //   };

  //   const rzpay = new window.Razorpay(options);
  //   rzpay.open();
  // };

  // const fetchPaymentDetails = async (InternId) => {
  //   try {
  //     const response = await axios.post(
  //       "https://practicoit.in/Server/Admin/fetchPaymentDetails.php",
  //       { InternId }
  //     );
  //     if (response.data.PaymentId) {
  //       navigate("/User-Dashboard");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching user data:", error);
  //   }
  // };

  //###### Payment verification by QR code ######

  // ### Date and time ###
    // current Data and time
    const currentDate = new Date();
    const offset = currentDate.getTimezoneOffset(); // Get timezone offset in minutes
    currentDate.setMinutes(currentDate.getMinutes() - offset); // Adjust for timezone offset
  
    // Format the date and time as a MySQL-compatible string
    const formatedDate = currentDate.toISOString().slice(0, 19).replace("T", " ");
    
  const [inputPaymentId, setInputPaymentId] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [transactionStatus, setTransactionStatus] = useState("");
  const handleInputChange = (e) => {
    setInputPaymentId(e.target.value);
  };

  const handleVerifyPayment = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/UserDashboard/PaymentGateway/paymentDetails.php",
        {
          internId: InternId,
          name: userName,
          email: email,
          contact: phone,
          paymentId: inputPaymentId,
          createdAt: formatedDate,
        }
      );
      console.log(response.data.message);
      if (response.data.status==="success") {
        setPaymentStatus("PaymentId Verification : Pending...");
        setInputPaymentId("");
        setTransactionStatus(response.data.status);
      } else {
        setPaymentStatus(response.data.message);
      }
    } catch (error) {
      console.error("Failed to verify payment", error);
      alert("Failed to verify payment.");
    }
  };

  return (
    <>
      {plan === "Premium" ? (
        <div className="position-relative z-1 overflow-hidden card mx-auto internshipScheduleCard">
          <div className="design">
            <span className="big-circle"></span>
            <span className="square"></span>
          </div>
          <div className="card-body z-2 text-center">
            <h2 className="card-title">Hello, {userName}</h2>

            <h3 className="card-title px-5">
              " Thank you for choosing our internship program. Kindly proceed
              with the payment process. "
            </h3>
            <div className="qr-code-container">
              <img src={paymentQR} alt="Payment QR Code" className="qr-code" />
              <p>Scan the QR code with any payment app to make a payment</p>
            </div>
            <div className="verify-payment-container">
              <h2 className="card-title my-6">Verify Payment</h2>
              <form onSubmit={handleVerifyPayment}>
                <div className="form-group m-0">
                  <label htmlFor="paymentId">Payment ID : </label>
                  <input
                    type="text"
                    id="paymentId"
                    name="paymentId"
                    value={inputPaymentId}
                    onChange={handleInputChange}
                    required
                    placeholder="Enter payment ID"
                  />
                </div>
                <button type="submit" className="btn m-4">
                  Verify Payment
                </button>
                {transactionStatus ==="success" ?
                  <>
                    <p>{paymentStatus}</p>
                    <p>
                      After payment verification, your dashboard will be
                      allotted within 2 days.
                    </p>
                  </>
                  :
                  <p>{paymentStatus}</p>
                }
              </form>
            </div>

            {/* <button onClick={handlePayment} className="btn m-4">
              Pay Now
            </button> */}
          </div>
        </div>
      ) : (
        <div className="position-relative z-1 overflow-hidden card mx-auto internshipScheduleCard">
          <div className="design">
            <span className="big-circle"></span>
            <span className="square"></span>
          </div>
          <div className="card-body z-2">
            <h3 className="card-title">Hello, {userName}</h3>
            <p className="card-text">
              Your web-related interview is scheduled in 15 days from now.
              Theoretical questions will delve into topics such as web
              development principles, front-end and back-end technologies, and
              best practices in web design and development. Expect questions on
              HTML, CSS, JavaScript, as well as frameworks and libraries such as
              React, Angular, or Vue.js, depending on your expertise.
              <br />
              During the practical segment, you'll have the opportunity to
              showcase your coding skills by solving real-world web development
              challenges. You may be asked to build a web application, debug
              existing code, or optimize performance issues.
              <br />
              Furthermore, if you have any web projects in your portfolio, don't
              hesitate to present them during the interview. Additionally,
              bringing along certifications or other relevant documents that
              highlight your skills and experience in web development can
              greatly bolster your candidacy.
              <br />
              Rest assured, I'll be attentive to any updates or notifications
              regarding the interview to ensure nothing is missed. If you
              require further assistance or have any additional queries, please
              don't hesitate to reach out. Best of luck with your interview
              preparation!
            </p>
          </div>
        </div>
      )}
    </>
  );
}

export default InterviewSchedule;
