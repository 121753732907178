import React from "react";
// import "./WhatWeDo";

function ServiceCards(props) {
  return (
    <div className="card serviceCard">
      <img src={`${props.img}`} alt="" className="card-img" />
      <div className="card-body">
        <h2 className="card-title">{props.serviceType}</h2>
        <p className="card-text">{props.content}</p>
      </div>
    </div>
  );
}

export default ServiceCards;
