import { useEffect, useState } from "react";
import HeroSection2 from "../components/HeroSection2";
import AboutSection from "../components/AboutSection";
import PerformanceCounter from "../components/PerformanceCounter";
import ClientTestimonial from "../components/ClientTestimonial";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../App.css";
import axios from "axios";
import aboutus from "../images/aboutus.png";

function About() {
  const [content, setContent] = useState("");
  const [subHeading, setSubHeading] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
    getuserdata("About");
  }, []);

  const getuserdata = async (sectionName) => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/WebContent/fetchWebComponent.php",
        { sectionName }
      );
      setContent(response.data[0]?.Description || "");
      setSubHeading(response.data[0]?.subHeading || "");
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  return (
    <>
      <Navbar />
      <HeroSection2
        pageTitle="About"
        pageSubtitle="It turns to the pathway for both employer and employe"
      />
      <AboutSection
        display="d-none"
        secTitle="About Us"
        subHeading={subHeading}
        Img={aboutus}
        content={content}
      />
      <PerformanceCounter />
      <ClientTestimonial />
      <Footer />
    </>
  );
}

export default About;
