import React from "react";
import "./PerformanceCounter.css";
import { Link } from "react-router-dom";

function HeroSection() {
  return (
    <>
      <div className="position-relative w-100" id="hero-img">
        <div
          className="position-absolute text-white d-flex flex-column align-items-start justify-content-center"
          id="hero-container"
        >
          <div className="container">
            <div className="col-md-6">
              <span style={{ color: "bbb" }} className="text-uppercase">
                Welcome
              </span>
              <h1 className="mb-4 mt-2 display-5 font-weight-bold text-color-light">
                Transform Your{" "}
                <span className="text-color-dark">
                  {" "}
                  Ideas into Digital Reality!
                </span>
              </h1>
              <p>
                Crafting Your Digital Dreams: Tailoring Bespoke Web and Mobile
                Applications to Meet Your Business Needs
              </p>
              <div className="mt-5">
                <Link to="/" className="btn px-5 py-3 mt-3 mt-sm-0">
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
