import React, { useRef, useState, useEffect } from "react";
import "../components/MultiStepForm.css"; // CSS file
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import "../Pages/UserDashboard.css";
import DashNavbar from "./DashNavbar";
import DashSidebar from "./DashSidebar";

function Plan() {
  const formRef = useRef(null);
  const [InternId, setInternId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [contitions, setConditions] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(""); // State to store selected plan
  const [formData, setFormData] = useState({
    internid: "",
    selectedPlan: "",
  });
  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan);
    // Update selected plan in form data
    setFormData({ ...formData, internid: InternId, selectedPlan: plan });
    setConditions(true);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      const decodedToken = jwtDecode(token);
      setInternId(decodedToken.internid);
      fetchData(decodedToken.internid);
    }
  }, []);

  const fetchData = async (InternId) => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/InternshipApplication/getUserData.php/getUserData",
        { InternId }
      );
      const userData = response.data;
      setFormData({
        internid: InternId,
        selectedPlan: userData.SelectedPlan,
      });
      setSelectedPlan(userData.SelectedPlan);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      if (contitions) {
        const response = await axios.post(
          "https://practicoit.in/Server/UserDashboard/updateUserDetails.php/update",
          formData
        );
        // console.log(formData.selectedPlan);
        console.log("Form submitted successfully", formData);
        if (response.data.status === 1) {
          setShowModal(true);
          setConditions(false);
          console.log("Form submitted:", response.data);
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  }

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <>
          <div
            className="modal fade show "
            tabIndex="-1"
            role="dialog"
            style={{ display: "block", backgroundColor: "#242f9b6e" }}
          >
            <div
              className="modal-dialog modal-dialog-centered "
              role="document"
            >
              <div
                className="modal-content text-light"
                style={{ backgroundColor: "#11164f" }}
              >
                <div className="modal-header">
                  <h5 className="modal-title text-light">Success</h5>
                  <button
                    type="button"
                    className="close d-flex align-items-center"
                    aria-label="Close"
                    onClick={closeModal}
                    style={{ color: "#ba0000", backgroundColor: "transparent" }}
                  >
                    <i className="fa-solid fa-circle-xmark fs-5"></i>
                    {/* <i className="fa-solid fa-square-xmark"></i> */}
                  </button>
                </div>
                <div className="modal-body">
                  Your form has been submitted successfully!
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div id="Dashboard-Main">
        <DashNavbar />
        <div id="wrapper">
          <DashSidebar />
          {/* Main Content */}
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <div className="container-fluid">
                <h2 className="heading p-3">Switch to Premium Plan</h2>
                <p className="text-center py-3">"Switching from the Free plan to Premium is easy – just send a request. Once your request is submitted, we'll promptly process it. If a slot is available, we'll provide you with access to switch to the Premium plan and proceed with payment at your convenience."</p>

                <form
                  ref={formRef}
                  className="py-5"
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <div className="row mt-3">
                    <div className="col-md-6">
                      {/* <!-- Pricing Card --> */}
                      <div className="pricing-card pricing-card1">
                        <div className="card shadow-sm border-none text-center mt-1">
                          <header
                            className={`card-header p-3  ${
                              selectedPlan === "Free"
                                ? "pricing-card-active"
                                : ""
                            }`}
                          >
                            <h2 className="h5 text-primary mb-4">
                              Free Plan
                            </h2>
                            <span className="d-block">
                              <span className="display-1 text-dark font-weight-bold">
                              &#8377; 0
                              </span>
                              <span className="d-block text-color-light font-small">
                                / Internship
                              </span>
                            </span>
                          </header>
                          <div
                            className={`card-body  ${
                              selectedPlan === "Free"
                                ? "pricing-card-active"
                                : ""
                            }`}
                          >
                            <ul className="list-group mb-4">
                              <li className="list-group-item">
                                Interview : Theory and Problem Solving
                              </li>
                              <li className="list-group-item">
                                Html, CSS, Javascript or Framework / Library
                              </li>
                              <li className="list-group-item">
                                Php / Django / Node.js or other
                              </li>
                              <li className="list-group-item">
                                Self made project
                              </li>
                              <li className="list-group-item">
                                Internship with Mentor Guidence
                              </li>
                              <li className="list-group-item">
                                30 Days Internship
                              </li>
                            </ul>
                            <input
                              type="button"
                              className={`btn btn-primary btn-block animate-up-1 ${
                                formData.selectedPlan === "Free"
                                  ? "selected"
                                  : ""
                              }`}
                              tabIndex={0}
                              value={`${
                                formData.selectedPlan === "Free"
                                  ? "Selected"
                                  : "Select"
                              }`}
                              onClick={() => handleSelectPlan("Free")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 ">
                      {/* <!-- Pricing Card --> */}
                      <div className="pricing-card pricing-card2">
                        <div className="card shadow-sm border-none text-center mt-1">
                          <header
                            className={`card-header p-3  ${
                              selectedPlan === "Charged"
                                ? "pricing-card-active"
                                : ""
                            }`}
                          >
                            <h2 className="h5 text-primary mb-4">
                              Premium Plan
                            </h2>
                            <span className="d-block">
                              <span className="display-1 text-dark font-weight-bold">
                                <span className="align-top font-medium">
                                  &#8377;
                                </span>
                                250
                              </span>
                              <span className="d-block text-color-light font-small">
                                / Internship
                              </span>
                            </span>
                          </header>
                          <div
                            className={`card-body  ${
                              selectedPlan === "Charged"
                                ? "pricing-card-active"
                                : ""
                            }`}
                          >
                            <ul className="list-group mb-4">
                              <li className="list-group-item">
                                No Interview Required
                              </li>
                              <li className="list-group-item">
                                Basic Knowledge of HTML, CSS, Javascript
                              </li>
                              <li className="list-group-item">
                                Basic Knowledge of Php
                              </li>
                              <li className="list-group-item">
                                Basic Project (Not Compulsary)
                              </li>
                              <li className="list-group-item">
                                Internship with Mentor Guidence
                              </li>
                              <li className="list-group-item">
                                15 Days training or 30 Days Internship
                              </li>
                            </ul>
                            <input
                              type="button"
                              className={`btn btn-secondary btn-block animate-up-1 ${
                                formData.selectedPlan === "Premium"
                                  ? "selected"
                                  : ""
                              }`}
                              tabIndex={0}
                              value={`${
                                formData.selectedPlan === "Premium"
                                  ? "Selected"
                                  : "Select"
                              }`}
                              onClick={() => handleSelectPlan("Premium")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="button-row d-flex mt-4 col-12">
                    
                    <button
                      className="btn btn-success ml-auto submit-btn"
                    >
                      Send Request 
                    </button>
                    
                    <button
                      className="btn btn-success ml-auto submit-btn"
                      type="submit"
                      title="Send"
                    >
                      Upgrade
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Plan;
