import React from "react";

function CoursePlayer() {
  return (
    <>
      {" "}
      <video src=""></video>
    </>
  );
}

export default CoursePlayer;
