import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import logo from "../images/mainlogo.png";
import { jwtDecode } from "jwt-decode";
import { FaBell } from "react-icons/fa";
import axios from "axios";

function DashNavbar() {
  const [username, setUsername] = useState("");
  const handleMenuToggle = (e) => {
    e.preventDefault();
    document.getElementById("wrapper").classList.toggle("toggled");
  };
  // Notification
  const [notificationCount, setNotificationCount] = useState(0);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      setUsername(decodedToken.username);
      fetchData(decodedToken.email);
    }
  }, []);
  const fetchData = async (email) => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/UserDashboard/getInboxData.php/getinboxdata",
        { email:email }
      );
      const statusZeroItems = response.data.filter((item) => item.Status === 0);
      const countStatusZero = statusZeroItems.length;
      setNotificationCount(countStatusZero);
    } catch (error) {
      console.error("Error fetching inbox data:", error);
    }
  };
  return (
    <>
      {" "}
      <nav className="navbar justify-content-between navbar-expand-lg navbar-dark">
        <div className="container-fluid justify-content-start px-0">
          <button
            className="menu-toggle custom-toggler collapsed btn navbar-toggler me-3"
            onClick={handleMenuToggle}
          >
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <NavLink to="/" className="navbar-logo ">
            <img src={logo} alt="Logo" className="logo" />
          </NavLink>
        </div>
        <div className="user-details align-items-center justify-content-end w-100">
          {/* Notification */}
          <div className="notification-container mx-5">
            <NavLink to="/User-Dashboard/Inbox">
              <div className="notification-bell">
                {/* Bell icon and notification count */}
                <FaBell />
                {notificationCount > 0 && <span>{notificationCount}</span>}
              </div>
            </NavLink>
          </div>
          {/* User login */}
          <div className="btn-group dropstart user-login">
            <span className="nav-link text-capitalize px-1 text-color-Exdark">
              Welcome, {username}
            </span>
          </div>
        </div>
      </nav>
    </>
  );
}

export default DashNavbar;
