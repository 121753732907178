import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import Home from "./Pages/Home";
import Services from "./Pages/Services";
import About from "./Pages/About";
import Blogs from "./Pages/Blogs";
import FullBlog from "./Pages/FullBlog";
import Contact from "./Pages/Contact";
import InternshipApply from "./Pages/InternshipApply";
import Sign from "./Pages/Sign";
import HireUs from "./Pages/HireUs";
import ForgotPassword from "./Pages/ForgotPassword";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import UserDashboard from "./Pages/UserDashboard";
import Status from "./dashboardComponent/Status";
import Courses from "./dashboardComponent/Courses";
import Inbox from "./dashboardComponent/Inbox";
import Plan from "./dashboardComponent/Plan";
import Profile from "./dashboardComponent/Profile";
import CoursePlayer from "./dashboardComponent/CoursePlayer";
import SEOPage from './components/SEOPage';


const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index element={<Home />} />
      <Route path="/improve-website-seo" component={SEOPage} />
      <Route path="Services" element={<Services />} />
      <Route path="About" element={<About />} />
      <Route path="Blogs" element={<Blogs />} />
      <Route path="/Blog/:idFullBlog" element={<FullBlog />} />
      <Route path="/Apply-For-Internship" element={<InternshipApply />} />
      <Route path="/Hire-For-Work" element={<HireUs />} />
      <Route path="/User-Dashboard" element={<UserDashboard />} />
      <Route path="/User-Dashboard/Status" element={<Status />} />
      <Route path="/User-Dashboard/Courses" element={<Courses />} />
      <Route path="/User-Dashboard/CoursesVideos" element={<CoursePlayer />} />
      <Route path="/User-Dashboard/Inbox" element={<Inbox />} />
      <Route path="/User-Dashboard/Plan" element={<Plan />} />
      <Route path="/User-Dashboard/Profile" element={<Profile />} />
      <Route path="Contact" element={<Contact />} />
      <Route path="Sign" element={<Sign />} />
      <Route path="ForgotPassword" element={<ForgotPassword />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
