import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./Footer.css";
import logo from "../images/mainlogo.png";

function Footer() {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/WebContent/FetchBlogs.php/fetchblog"
      );
      // Sorting the data by 'created_at' in descending order
      const sortedData = response.data.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      setData(sortedData.slice(0, 3)); // Get only the latest 3 blogs
    } catch (error) {
      console.error("Error fetching blog data:", error);
    }
  };

  return (
    <>
      <footer className="footer-section">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="widget company-intro-widget">
                  <Link to="/" className="footer-logo">
                    <img src={logo} alt="Logo" width="150px" className="my-3" />
                  </Link>
                  <p>
                    Crafting Your Digital Dreams: Tailoring Bespoke Web and
                    Mobile Applications to Meet Your Business Needs
                  </p>
                  <ul className="company-footer-contact-list">
                    <li>
                      <i className="fas fa-phone"></i>
                      <a href="tel:9911859914" className="contact">
                        9911859914
                      </a>
                    </li>
                    <li>
                      <i className="fa-solid fa-envelope"></i>
                      <a
                        href="mailto:official@practicoit.in"
                        className="contact"
                      >
                        official@practicoit.in
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="widget course-links-widget">
                  <h5 className="widget-title">Internships</h5>
                  <ul className="courses-link-list">
                    <li>
                      <a href="./">
                        <i className="fas fa-long-arrow-alt-right"></i>{" "}
                        FullStack Web Development
                      </a>
                    </li>
                    <li>
                      <a href="./">
                        <i className="fas fa-long-arrow-alt-right"></i>MERN
                        Stack
                      </a>
                    </li>
                    <li>
                      <a href="./">
                        <i className="fas fa-long-arrow-alt-right"></i>MARN
                        Stack
                      </a>
                    </li>
                    <li>
                      <a href="./">
                        <i className="fas fa-long-arrow-alt-right"></i>Apps
                        Development
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="widget latest-news-widget">
                  <h5 className="widget-title">Latest News</h5>
                  <ul className="small-post-list">
                    {Array.isArray(data) && data.length > 0
                      ? data.map((item, index) => (
                          <Link
                            key={index}
                            to={`/Blog/${item.BlogHeading.replace(
                              /\s+/g,
                              "-"
                            )}`}
                          >
                            <h6 className="blog-title m-1">
                              {item.BlogHeading}
                            </h6>
                            <p className="my-2 d-flex justify-content-between datetime">
                              <small>
                                {new Date(item.created_at).toLocaleString()}
                              </small>
                            </p>
                          </Link>
                        ))
                      : null}
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="widget newsletter-widget">
                  <h5 className="widget-title">Newsletter</h5>
                  <div className="footer-newsletter">
                    <p>
                      Sign Up to Our Newsletter to Get Latest Updates & Services
                    </p>
                    <Link to="/Contact" className="btn subs-btn">
                      Subscribe
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-6 text-sm-left">
                <span className="copy-right-text">
                  © 2024 <a href="./">PracticoIt</a> All Rights Reserved.
                </span>
              </div>
              <div className="col-md-6 col-sm-6">
                <ul className="terms-privacy d-flex justify-content-sm-end justify-content-center">
                  <li>
                    <a href="./">Terms & Conditions</a>
                  </li>
                  <li>
                    <a href="./">Privacy Policy</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="text-center designer text-color-dark">
              Designed By - Praveen Kumar
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
