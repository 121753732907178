import React from "react";
import { Helmet } from "react-helmet";

function SEOPage() {
  return (
    <div>
      <Helmet>
        <title>
          Welcome to Our IT Firm - Internships, Website, and App Development
        </title>
        <meta
          name="description"
          content="Join our leading IT firm offering top-notch internships in IT fields and professional services in website and app development. Elevate your skills and get hands-on experience with our expert team. Contact us today to learn more."
        />
        <meta
          name="keywords"
          content="IT internships, website development, app development, IT firm, software development, programming internships, IT training,Internship,internship,IT,practicoit,Practicoit,PracticoIT,PRACTICOIT"
        />
      </Helmet>
      <h1>Welcome to Our IT Firm</h1>
      <p>
        We provide top-notch internships in various IT fields and specialize in
        professional website and app development services. Join us to elevate
        your skills and get hands-on experience with our expert team.
      </p>
    </div>
  );
}

export default SEOPage;
