import React, { useEffect } from "react";
import HeroSection2 from "../components/HeroSection2";
import "../components/ContactForm.css";
import MultiStepForm from "../components/MultiStepForm";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../App.css";

function InternshipApply() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  return (
    <>
      <Navbar />
      <HeroSection2
        pageTitle="Internship"
        pageSubtitle="Launch Your Career with Our Internship Program!"
      />
      <MultiStepForm />
      <Footer />
    </>
  );
}

export default InternshipApply;
