import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "./FullBlog.css";
import "../App.css";

function FullBlog() {
  const [blogdata, setBlogdata] = useState(null);
  const [alldata, setAlldata] = useState([]);
  const [comments, setComments] = useState([]);
  const { idFullBlog } = useParams();

  useEffect(() => {
    fetchData();
    fetchComments();
    window.scrollTo(0, 0);
  }, [idFullBlog]);

  const fetchData = async () => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/WebContent/FetchBlogs.php/fetchblog"
      );
      const filteredBlog = response.data.find((item) =>
        item.BlogHeading.replace(/\s+/g, "-")
          .toLowerCase()
          .includes(idFullBlog.toLowerCase())
      );
      filteredBlog && setBlogdata(filteredBlog);
      setAlldata(response.data);
    } catch (error) {
      console.error("Error fetching blog data:", error);
    }
  };

  // ######## Date and Time ########
  const currentDate = new Date();
  const offset = currentDate.getTimezoneOffset();
  currentDate.setMinutes(currentDate.getMinutes() - offset);
  const formatedDate = currentDate.toISOString().slice(0, 19).replace("T", " ");

  const fetchComments = async () => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/WebContent/fetchBlogComments.php",
        { blogId: idFullBlog }
      );
      if (response.status === "error") {
        setComments("");
      } else {
        setComments(response.data);
      }
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const commentData = {
      name: formData.get("name"),
      email: formData.get("email"),
      comment: formData.get("comment"),
      blogId: idFullBlog,
      date: formatedDate,
    };

    try {
      await axios.post(
        "https://practicoit.in/Server/WebContent/insertBlogComment.php",
        commentData
      );
      event.target.reset();
      fetchComments();
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };

  return (
    <>
      <Navbar />
      <div>
        <div className="row g-0">
          <div className="col-md-6">
            <div className="BlogCard-container rounded">
              <img
                src={`https://practicoit.in/Server/WebContent/${
                  blogdata && blogdata.BlogImage
                }`}
                alt=""
                className="rounded"
              />
            </div>
          </div>
          <div className="col-md-6 p-10 blogContent">
            <div className="card-body d-flex flex-column justify-content-center h-100">
              <h1
                className="card-title blog-card-title my-5"
                style={{ fontSize: "3.5em" }}
              >
                {blogdata && blogdata.BlogHeading}
              </h1>
              <p className="card-text m-2 d-flex justify-content-between w-75 text-color-Exdark">
                <small>
                  <i className="fas fa-calendar m-1"></i>
                  {blogdata && blogdata.created_at}
                </small>
                <small>-</small>
                <small>
                  <i className="fas fa-comments m-1"></i>
                  {comments.length ? comments.length : 0} Comments
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-5 border-none">
        <div className="row g-0 m-5">
          <div className="col-md-8 p-10 blogContent">
            <p>{blogdata && blogdata.BlogContent}</p>

            <div>
              <h1 className="card-title my-4 mt-10 comment-heading">
                Comments
              </h1>
              <div className="card-body h-100 comments-card my-10">
                {Array.isArray(comments) && comments.length > 0 ? (
                  comments.map((comment, index) => (
                    <>
                      <div className="d-flex comment-card-items" key={index}>
                        <span className="rounded comment-card-icon  bg-primary-subtle text-primary-emphasis">
                          <i className="fas fa-user"></i>
                        </span>
                        <div>
                          <h3 className="text-primary-emphasis">
                            {comment.Name}
                          </h3>
                          <p className="card-text">{comment.Comment}</p>
                        </div>
                      </div>
                    </>
                  ))
                ) : (
                  <h3>No Comments</h3>
                )}
              </div>
            </div>

            <form className="row g-0 comment-form" onSubmit={handleFormSubmit}>
              <h1
                className="card-title my-4 comment-heading"
                style={{ fontSize: "2em" }}
              >
                Leave a Comment
              </h1>
              <div className="col-12 my-3">
                <input
                  type="text"
                  name="name"
                  className="form-control border-2"
                  id="inputName"
                  placeholder="Name"
                  required
                />
              </div>
              <div className="col-md-12 my-3">
                <input
                  type="email"
                  name="email"
                  className="form-control border-2"
                  id="inputEmail4"
                  placeholder="Email"
                  required
                />
              </div>
              <div className="col-12 my-3">
                <textarea
                  name="comment"
                  className="form-control border-2"
                  placeholder="Leave a comment here"
                  id="floatingTextarea"
                  style={{ height: "150px" }}
                  required
                ></textarea>
              </div>
              <div className="col-12">
                <button
                  type="submit"
                  className="btn btn-primary w-100"
                  style={{ backgroundColor: "#1abc9c" }}
                >
                  <i className="fas fa-paper-plane"></i> Send
                </button>
              </div>
            </form>
          </div>
          <div className="col-md-4">
            <h3 className="m-5 fs-3">Recent Posts</h3>
            <div className=" recent-posts border-3 rounded mb-5">
              {Array.isArray(alldata) && alldata.length > 0
                ? alldata.map((item, index) => (
                    <Link
                      key={index}
                      to={`/Blog/${item.BlogHeading.replace(/\s+/g, "-")}`}
                    >
                      <div className="row g-0">
                        <div className="col-md-4 AllBlogs-sidebar d-flex justify-content-center align-items-center m-0 w-25">
                          <img
                            src={`https://practicoit.in/Server/WebContent/${item.BlogImage}`}
                            alt=""
                          />
                        </div>
                        <div className="col-md-8">
                          <div className="card-body px-3">
                            <h3
                              className="card-title blog-card-title m-1"
                              style={{ fontSize: "1em" }}
                            >
                              {item.BlogHeading}
                            </h3>
                            <p className="card-text d-flex justify-content-between text-color-Exdark">
                              <small>{item.created_at}</small>
                              {/* <small>.</small> */}
                              {/* <small>No Comments</small> */}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))
                : null}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default FullBlog;
