import React from "react";
import { NavLink,useNavigate } from "react-router-dom";

function DashSidebar() {
  const navigator = useNavigate();
  // Function to toggle sidebar menu
  const handleMenuToggle = (e) => {
    e.preventDefault();
    document.getElementById("wrapper").classList.toggle("toggled");
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("loginTime");
    navigator("/sign");
    // window.location.reload();
  };

  return (
    <>
      <div id="sidebar-wrapper">
        <ul className="sidebar-nav">
          <li>
            <NavLink to="/User-Dashboard">
              <i className="fa-solid fa-home"></i>
              <span>Dashboard</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/User-Dashboard/Status">
              {/* <i className="fa-solid fa-file-alt"></i> */}
              <i className="fas fa-chart-line"></i>

              <span>Status</span>
            </NavLink>
          </li>
          {/* <li>
            <NavLink to="/User-Dashboard/Courses">
              <i className="fa-solid fa-video"></i>
              <span>Training</span>
            </NavLink>
          </li> */}
          <li>
            <NavLink to="/User-Dashboard/Inbox">
              <i className="fa-solid fa-message"></i>
              <span>Inbox</span>
            </NavLink>
          </li>
          {/* <li>
            <NavLink to="/User-Dashboard/Plan">
              <i className="fas fa-gem"></i>
              <span>Plans</span>
            </NavLink>
          </li> */}
          <li>
            <NavLink to="/User-Dashboard/Profile">
              <i className="fa-solid fa-user"></i>
              <span>Profile</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/" onClick={handleLogout}>
              <i className="fa-solid fa-right-from-bracket"></i>
              <span>Logout</span>
            </NavLink>
          </li>
        </ul>
        <button
          className="menu-toggle sidebar-toggle bg-transparent p-0 "
          onClick={handleMenuToggle}
        >
          <i className="fa fa-lg fa-caret-right fs-4"></i>
        </button>
      </div>
    </>
  );
}

export default DashSidebar;
