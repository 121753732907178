import React, { useEffect, useState } from "react";
import "../App.css";
import HeroSection from "../components/HeroSection";
import PerformanceCounter from "../components/PerformanceCounter";
import AboutSection from "../components/AboutSection";
import WhatWeDo from "../components/WhatWeDo";
import FAQs from "../components/FAQs";
import ClientTestimonial from "../components/ClientTestimonial";
import BlogSection from "../components/BlogSection";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import axios from "axios";
import aboutus from "../images/aboutus.png";

function Home() {
  const [content, setContent] = useState("");
  const [subHeading, setSubHeading] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
    getuserdata("About");
  }, []);

  const getuserdata = async (sectionName) => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/WebContent/fetchWebComponent.php",
        { sectionName }
      );
      setContent(response.data[0]?.Description || "");
      setSubHeading(response.data[0]?.subHeading || "");
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  return (
    <>
      <Navbar />
      <HeroSection />
      <AboutSection
        secTitle="About Us"
        subHeading={subHeading}
        Img={aboutus}
        content={content ? content.slice(0, 366) : ""}
      />
      <PerformanceCounter />
      <WhatWeDo />
      <FAQs />
      <ClientTestimonial />
      <BlogSection />
      <Footer />
    </>
  );
}

export default Home;
