import React, { useEffect } from "react";
import BlogCard from "../components/BlogCard";
import HeroSection2 from "../components/HeroSection2";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../App.css";
import axios from "axios";

function Blogs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const [data, setData] = React.useState([]);
  const fetchData = async () => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/WebContent/FetchBlogs.php/fetchblog"
      );
      setData(response.data);
    } catch (error) {
      console.error("Error fetching inbox data:", error);
    }
  };

  return (
    <>
      <Navbar />
      <HeroSection2 pageTitle="Blogs" pageSubtitle="Archives" />
      <div className="d-flex flex-wrap justify-content-center align-items-center my-20 mx-3">
        {Array.isArray(data) && data.length > 0
          ? data.map((item, index) => (
              <Link
                key={index}
                to={`/Blog/${item.BlogHeading.replace(/\s+/g, "-")}`}
              >
                <BlogCard
                  title={item.BlogHeading ? item.BlogHeading.slice(0, 35) : ""}
                  content={
                    item.BlogContent ? item.BlogContent.slice(0, 100) : ""
                  }
                  imgUrl={item.BlogImage}
                  createdAt={item.created_at}
                />
              </Link>
            ))
          : null}
      </div>
      <Footer />
    </>
  );
}

export default Blogs;
