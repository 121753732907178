import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
// import logo from "../images/logo.png";
import logo from "../images/mainlogo.png";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
// import logo2 from "../images/logo2.png";

function Navbar() {
  const navigator = useNavigate();
  const [InternId, setInternId] = useState("");
  const [status, setStatus] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");

  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const storedDarkMode = localStorage.getItem("darkMode");
    if (storedDarkMode === "true") {
      setDarkMode(true);
      document.body.classList.add("dark-mode");
    }
  }, []);

  const toggleDarkMode = () => {
    setDarkMode(true);
    localStorage.setItem("darkMode", "true");
    document.body.classList.add("dark-mode");
  };

  const toggleLightMode = () => {
    setDarkMode(false);
    localStorage.setItem("darkMode", "false");
    document.body.classList.remove("dark-mode");
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      setUsername(decodedToken.username);
      setEmail(decodedToken.email);
      setInternId(decodedToken.internid);
    }
  }, []);

  useEffect(() => {
    if (InternId !== "") {
      getuserdata(InternId);
      fetchPaymentDetails(InternId);
    }
  }, [InternId]);

  const getuserdata = async (InternId) => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/InternshipApplication/getUserData.php/getUserdata",
        { InternId: InternId }
      );
      setStatus(response.data.Status);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    const handleCollapseNavbar = (event) => {
      const navbarCollapse = document.getElementById("navbarSupportedContent");
      const navbarToggler = document.querySelector(".navbar-toggler");

      // Check if the clicked element is inside the navbar
      if (
        navbarCollapse &&
        !navbarCollapse.contains(event.target) &&
        !navbarToggler.classList.contains("collapsed")
      ) {
        navbarToggler.click(); // Collapse the navbar if it's not already collapsed
      }
    };

    document.addEventListener("click", handleCollapseNavbar);

    return () => {
      document.removeEventListener("click", handleCollapseNavbar);
    };
  }, []);

  // Logout function
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("loginTime");
    navigator("/sign");
    // window.location.reload();
  };

  // Function to handle link click and collapse navbar
  const handleLinkClick = () => {
    const navbarToggler = document.querySelector(".navbar-toggler");
    if (!navbarToggler.classList.contains("collapsed")) {
      navbarToggler.click();
    }
  };

  //######## Fetch PaymentId ########
  const [paymentDetails, setPaymentDetails] = useState();
  const fetchPaymentDetails = async (InternId) => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/Admin/fetchPaymentDetails.php",
        { InternId }
      );

      if (response.data.PaymentId === undefined) {
        setPaymentDetails("no data found");
      } else {
        setPaymentDetails(response.data.PaymentId);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg px-2 py-2 ">
        <div className="container-fluid container-xl p-0 align-items-center">
          <NavLink to="/" className="navbar-logo py-0">
            <img src={logo} alt="" className="logo" />
          </NavLink>
          <div className="d-flex  justify-content-end align-items-center hamburger">
            {/* ##### Signin or Sign Out Button ##### */}

            {username ? (
              <>
                <div className="btn-group dropstart user-login mobile-show">
                  <div
                    className="user-login-detail"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="nav-link text-capitalize px-1">
                      Welcome, {username}
                    </span>
                  </div>
                  <ul className="dropdown-menu ">
                    <li className="text-capitalize d-flex dropdown-item">
                      <i className="fa-solid fa-user m-2"></i>
                      <span className="text-capitalize d-flex flex-column">
                        <b>{username}</b>
                        <small>{email}</small>
                      </span>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li onClick={handleLogout} className="dropdown-item">
                      <i className="fa-solid fa-right-from-bracket mx-2"></i>
                      Logout
                    </li>
                  </ul>
                </div>
              </>
            ) : (
              <NavLink className="nav-link mobile-show" to="/Sign">
                <button type="button" className="btn btn-sm w-full  w-lg-auto">
                  Signup / Login
                </button>
              </NavLink>
            )}
            {/* ##### Dark mode toggle button ##### */}
            <div className="btn-group dropstart mode px-1">
              <button
                type="button"
                className=" toggle-btn border-0 outline-0"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {darkMode ? (
                  <i className="fas fa-moon"></i>
                ) : (
                  <i className="fas fa-sun"></i>
                )}
              </button>
              <ul className="dropdown-menu text-center">
                <li>
                  <button
                    className="dropdown-item"
                    onClick={() => setDarkMode(false)}
                  >
                    <i className="fas fa-sun"></i>
                    Light
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item"
                    onClick={() => setDarkMode(true)}
                  >
                    <i className="fas fa-moon"></i>
                    Dark
                  </button>
                </li>
              </ul>
            </div>
            {/* ##### hamburger ##### */}

            <button
              className="navbar-toggler custom-toggler collapsed shadow-none"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
          </div>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mx-lg-auto align-items-center">
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  aria-current="page"
                  to="/"
                  onClick={handleLinkClick}
                >
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/Services"
                  onClick={handleLinkClick}
                >
                  Services
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/Blogs"
                  onClick={handleLinkClick}
                >
                  Blog
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/About"
                  onClick={handleLinkClick}
                >
                  About
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/Contact"
                  onClick={handleLinkClick}
                >
                  Contact
                </NavLink>
              </li>
              {username ? (
                <>
                  {status === "Selected" || paymentDetails === InternId ? (
                    // Condition true: Display Internship link
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        to={`/User-Dashboard`}
                        onClick={handleLinkClick}
                      >
                        Dashboard
                      </NavLink>
                    </li>
                  ) : (
                    // Condition false: Display Dashboard link
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        to={`/Apply-For-Internship`}
                        onClick={handleLinkClick}
                      >
                        Internship
                      </NavLink>
                    </li>
                  )}
                </>
              ) : null}
            </ul>
            <div className="user-details">
              {username ? (
                <>
                  <div className="btn-group dropstart user-login">
                    <div
                      className="user-login-detail"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="nav-link text-capitalize px-1">
                        Welcome, {username}
                      </span>
                    </div>
                    <ul className="dropdown-menu ">
                      <li className="text-capitalize d-flex dropdown-item">
                        <i className="fa-solid fa-user m-2"></i>
                        <span className="text-capitalize d-flex flex-column">
                          <b>{username}</b>
                          <small>{email}</small>
                        </span>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li onClick={handleLogout} className="dropdown-item">
                        <i className="fa-solid fa-right-from-bracket mx-2"></i>
                        Logout
                      </li>
                    </ul>
                  </div>
                </>
              ) : (
                <NavLink className="nav-link" to="/Sign">
                  <button
                    type="button"
                    className="btn btn-sm w-full  w-lg-auto"
                  >
                    Signup / Login
                  </button>
                </NavLink>
              )}
              <div className="btn-group dropstart mode px-1 toggle-modes">
                <button
                  type="button"
                  className=" toggle-btn border-0 outline-0"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {darkMode ? (
                    <i className="fas fa-moon"></i>
                  ) : (
                    <i className="fas fa-sun"></i>
                  )}
                </button>
                <ul className="dropdown-menu text-center">
                  <li>
                    <button className="dropdown-item" onClick={toggleLightMode}>
                      <i className="fas fa-sun"></i>
                      Light
                    </button>
                  </li>
                  <li>
                    <button className="dropdown-item" onClick={toggleDarkMode}>
                      <i className="fas fa-moon"></i>
                      Dark
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
