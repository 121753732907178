import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "./ForgotPassword.css"; // Import custom CSS for styling

function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [otp, setOTP] = useState("");
  const [isOTPGenerated, setIsOTPGenerated] = useState(false);
  const [countdown, setCountdown] = useState(0); // Countdown starts at 0 initially

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [countdown]);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setError("");
  };

  const handleOTPChange = (event) => {
    setOTP(event.target.value);
    setError("");
  };

  const handleNewPasswordChange = (event) => {
    const password = event.target.value;
    // Check validation after each keystroke
    const regex =
      /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/; // Regex for strong password
    if (!regex.test(password)) {
      setError(
        "Password must contain at least one capital letter, one number, and one special character."
      );
    } else {
      setError(""); // Clear the error message if validation passes
    }
    setNewPassword(password); // Always update the new password state
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    setError("");
  };

  const handleSendOTP = async (event) => {
    event.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError("Please enter a valid email address");
      return;
    }

    try {
      const emailCheckResponse = await axios.post(
        "https://practicoit.in/Server/WebContent/checkEmail.php",
        {
          email: email,
        }
      );

      if (emailCheckResponse.data.exists) {
        const otpResponse = await axios.post(
          "https://practicoit.in/Server/WebContent/SendOTP.php",
          {
            email: email,
          }
        );
        if (otpResponse.data.error) {
          setMessage("");
          setError(otpResponse.data.error);
          return;
        }
        setMessage(otpResponse.data.message);
        setIsOTPGenerated(true);
        setCountdown(300); // Start countdown when OTP is sent
      } else {
        setError("Email does not exist. Please enter a valid email address.");
      }
    } catch (error) {
      setMessage("");
      setError("Failed to send OTP. Please try again later.");
    }
  };

  const handleVerifyOTP = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        "https://practicoit.in/Server/WebContent/VerifyOTP.php",
        {
          otp: otp,
        }
      );
      setError(response.data.error);
      setMessage(response.data.message);
    } catch (error) {
      console.error("Failed to verify OTP:", error);
      setMessage("");
      setError("Failed to verify OTP. Please try again.");
    }
  };

  const handleUpdatePassword = async (event) => {
    event.preventDefault();

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      const response = await axios.post(
        "https://practicoit.in/Server/signup&signin/UpdatePassword.php",
        {
          email: email,
          newPassword: newPassword,
        }
      );
      setMessage(response.data.message);
      setTimeout(() => {
        navigate("/sign");
      }, 3000); // Navigate to "/sign" after 3 seconds
    } catch (error) {
      console.error("Failed to update password:", error);
      setMessage("");
      setError("Failed to update password. Please try again.");
    }
  };

  return (
    <>
      <Navbar />
      <div className="container">
        <div className="row forgotPasswordForm">
          <div className="col-md-6 col-md-offset-3">
            <div className="forgot-password-panel">
              <div className="panel-body">
                <h2 className="text-center">Forgot Password?</h2>
                <p className="text-center">
                  Enter your email to receive a one-time password (OTP) to reset
                  your password.
                </p>
                <form
                  onSubmit={isOTPGenerated ? handleVerifyOTP : handleSendOTP}
                  className="forget-password-form"
                >
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Your Email"
                      value={email}
                      onChange={handleEmailChange}
                      required
                    />
                  </div>
                  {!isOTPGenerated && (
                    <button
                      type="submit"
                      className="btn btn-primary btn-block forgot-password-btn"
                    >
                      Send OTP
                    </button>
                  )}
                  {isOTPGenerated && (
                    <>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter OTP"
                          value={otp}
                          onChange={handleOTPChange}
                          required
                        />
                      </div>
                      {!message && (
                        <div className="my-2 message">
                          {countdown > 0
                            ? `OTP is valid for ${Math.floor(
                                countdown / 60
                              )}:${(countdown % 60).toLocaleString("en-US", {
                                minimumIntegerDigits: 2,
                              })}`
                            : "OTP expired"}
                        </div>
                      )}

                      {(error === "Invalid OTP" || error==="Failed to verify OTP. Please try again.")&& (
                        <div className="text-danger message">{error}</div>
                      )}
                      {message && message !== "Password updated successfully" && <div className="my-2 message">{message}</div>}
                      {isOTPGenerated &&
                        message !== "OTP verified successfully" &&
                        message !== "Password updated successfully" && (
                          <button
                            type="submit"
                            className="btn btn-primary btn-block forgot-password-btn"
                          >
                            Verify OTP
                          </button>
                        )}
                    </>
                  )}
                </form>
                {(message === "OTP verified successfully" ||
                  message === "Password updated successfully") && (
                  <form
                    onSubmit={handleUpdatePassword}
                    className="forget-password-form"
                  >
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="New Password"
                        value={newPassword}
                        onChange={handleNewPasswordChange}
                        required
                      />
                    </div>
                    {error ===
                      "Password must contain at least one capital letter, one number, and one special character." && (
                      <div className="text-danger message">{error}</div>
                    )}
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        required
                      />
                    </div>
                    {message === "Password updated successfully" && (
                      <div className="my-2 message">{message}</div>
                    )}
                    {error === "Passwords do not match" && (
                      <div className="text-danger message">{error}</div>
                    )}
                    <button
                      type="submit"
                      className="btn btn-primary btn-block forgot-password-btn"
                    >
                      Update Password
                    </button>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ForgotPassword;
