import React, { useState, useEffect,useCallback  } from "react";
import "./SignUp.css";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";

function SignIn() {
  const navigator = useNavigate();
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    username: "",
    password: "",
    serverError: "",
  });

  const [isLoggedIn, setIsLoggedIn] = useState(false);


  const handleLogout = useCallback(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("loginTime");
    setIsLoggedIn(false);
    navigator("/sign");
  }, [navigator]); 
  


  useEffect(() => {
    const token = localStorage.getItem("token");
    const loginTime = localStorage.getItem("loginTime");

    const checkLoginStatus = () => {
      if (token && loginTime) {
        try {
          const timeElapsed = Date.now() - parseInt(loginTime);
          const hoursElapsed = timeElapsed / (1000 * 60 * 60);
          
          if (hoursElapsed < 24) {
            setIsLoggedIn(true);
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error("Error checking login status:", error);
          handleLogout();
        }
      }
    };

    checkLoginStatus();

  }, [handleLogout]); 


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await axios.post(
          "https://practicoit.in/Server/signup&signin/signin.php/signup/save",
          formData
        );
        if (response.data.status === 1) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("loginTime", Date.now().toString());
          setIsLoggedIn(true);
          navigator("/");
        } else {
          setErrors({
            serverError: response.data.message,
          });
          console.log("Login failed:", response.data);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    } else {
      console.log("Form submission failed due to validation errors.");
    }
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      username: "",
      password: "",
      serverError: "",
    };

    if (formData.username.trim() === "") {
      newErrors.username = "Please enter your username.";
      valid = false;
    }

    if (formData.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters long.";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  if (isLoggedIn) {
    return (
      <div>
        <p>You are logged in!</p>
        <button onClick={handleLogout}>Logout</button>
      </div>
    );
  }

  return (
    <>
      <form
        className="sign-in-form"
        onSubmit={handleSubmit}
        noValidate
        // autoComplete="off"
      >
        <h2 className="title">Sign In</h2>
        <div className="input-field">
          <i className="fas fa-user"></i>
          <input
            type="text"
            placeholder="Username"
            className={`form-control ${errors.username && "is-invalid"}`}
            name="username"
            value={formData.username}
            onChange={handleInputChange}
            autoComplete="username"
            required
          />
          {errors.username && (
            <div className="invalid-feedback">{errors.username}</div>
          )}
        </div>
        <div className="input-field">
          <i className="fas fa-lock"></i>
          <input
            type="password"
            placeholder="Password"
            className={`form-control ${errors.password && "is-invalid"}`}
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            minLength="6"
            autoComplete="password"
            required
          />
          {errors.password && (
            <div className="invalid-feedback">{errors.password}</div>
          )}
        </div>
        {errors.serverError && (
          <div className="text-danger">{errors.serverError}</div>
        )}

        {/* <a href="ForgotPassword" className="forgot-password-link">Forgot password?</a> */}
        <Link to="/forgotpassword" className="forgot-password-link">
          Forgot password?
        </Link>
        <button type="submit" className="btn solid sign-btn">
          Login
        </button>
      </form>
    </>
  );
}

export default SignIn;
