import React, { useState, useEffect } from "react";
import "./SignUp.css";
import axios from "axios";

function SignUp() {
  const [otp, setOTP] = useState("");
  const [isOTPGenerated, setIsOTPGenerated] = useState(false);
  const [countdown, setCountdown] = useState(300); // Countdown starts at 5 minutes
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    username: "",
    email: "",
    password: "",
  });

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [countdown]);

  const handleOTPChange = (event) => {
    setOTP(event.target.value);
  };

  const handleEmailChange = (event) => {
    setFormData({ ...formData, email: event.target.value });
    setErrors({ ...errors, email: "" }); // Clear email error on email change
  };

  const handleNewPasswordChange = (event) => {
    const password = event.target.value;
    // Check validation after each keystroke
    const regex =
      /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/; // Regex for strong password
    if (!regex.test(password)) {
      setErrors({
        ...errors,
        password:
          "Password must contain at least one capital letter, one number, and one special character.",
      });
    } else {
      setErrors({ ...errors, password: "" }); // Clear password error if validation passes
    }
    setFormData({ ...formData, password: password }); // Always update the password state
  };

  const handleConfirmPasswordChange = (event) => {
    const confirmPassword = event.target.value;
    if (confirmPassword !== formData.password) {
      setErrors({ ...errors, password: "Passwords do not match" });
    } else {
      setErrors({ ...errors, password: "" }); // Clear password error if passwords match
    }
    setFormData({ ...formData, confirmPassword: confirmPassword });
  };

  const handleSendOTP = async (event) => {
    event.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setErrors({ ...errors, email: "Please enter a valid email address" });
      return;
    }

    try {
      const emailCheckResponse = await axios.post(
        "https://practicoit.in/Server/WebContent/checkEmail.php",
        {
          email: formData.email,
        }
      );

      if (!emailCheckResponse.data.exists) {
        const otpResponse = await axios.post(
          "https://practicoit.in/Server/WebContent/SendOTP.php",
          {
            email: formData.email,
          }
        );

        
        if (otpResponse.data.error) {
          setMessage("");
          setError(otpResponse.data.error);
          return;
        }
        setMessage(otpResponse.data.message);

        setIsOTPGenerated(true);
        setCountdown(300);
      } else {
        setError("Email already exists. Please try again with a different email.");
      }
    } catch (error) {
      setMessage("");
      setError("Failed to send OTP. Please try again later.");
    }
  };

  const handleVerifyOTP = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        "https://practicoit.in/Server/WebContent/VerifyOTP.php",
        {
          otp: otp,
        }
      );
      setErrors({ ...errors, serverError: response.data.error });
      setMessage(response.data.message);
    } catch (error) {
      setErrors({
        ...errors,
        serverError: "Failed to verify OTP. Please try again.",
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "", // Clear specific field error on input change
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      try {
        const response = await axios.post(
          "https://practicoit.in/Server/signup&signin/insert.php/signup/save",
          formData
        );
        if (response.data.status === 0) {
          setErrors({ ...errors, serverError: response.data.error });
        } else {
          window.location.reload();
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    } else {
      // Form is invalid, do nothing as error messages will be displayed
    }
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      username: "",
      email: "",
      password: "",
      serverError: "", // Reset serverError on form submit
    };

    // Validate username
    if (formData.username.trim() === "") {
      newErrors.username = "Username is required";
      valid = false;
    }

    // Validate email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.match(emailPattern)) {
      newErrors.email = "Invalid email address";
      valid = false;
    }

    // Validate password
    if (formData.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters long";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  return (
    <>
      {message !== "OTP verified successfully" && (
        <form
          onSubmit={isOTPGenerated ? handleVerifyOTP : handleSendOTP}
          className="sign-up-form needs-validation"
        >
          <h2 className="title">Sign Up</h2>
          {/* <h2 className="text-center text-color-Exdark">Email Verification</h2> */}
          <div className="input-field">
            <i className="fas fa-envelope"></i>
            <input
              type="email"
              className={`form-control ${errors.email && "is-invalid"}`}
              placeholder="Email"
              name="email"
              value={formData.email}
              onChange={handleEmailChange}
              required
            />
            {errors.email && (
              <div className="invalid-feedback">{errors.email}</div>
            )}
          </div>
          {error === "Email already exists. Please try again with a different email." && (
            <div className="my-2 message text-danger">{error}</div>
          )}

          {!isOTPGenerated && (
            <button
              type="submit"
              className="btn btn-primary btn-block forgot-password-btn"
            >
              Send OTP
            </button>
          )}
          {isOTPGenerated && (
            <>
              <div className="input-field">
                <i className="fas fa-key"></i>
                <input
                  type="text"
                  className={`form-control ${errors.email && "is-invalid"}`}
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={handleOTPChange}
                  required
                />
              </div>
              {!message && (
                <div className="my-2 message">
                  {countdown > 0
                    ? `OTP is valid for ${Math.floor(countdown / 60)}:${(
                        countdown % 60
                      ).toLocaleString("en-US", {
                        minimumIntegerDigits: 2,
                      })}`
                    : "OTP expired"}
                </div>
              )}

              {error === "Invalid OTP" ||
                (error === "Failed to verify OTP. Please try again." && (
                  <div className="text-danger message">{error}</div>
                ))}
              {isOTPGenerated &&
                message !== "OTP verified successfully" &&
                message !== "Password updated successfully" && (
                  <button
                    type="submit"
                    className="btn btn-primary btn-block forgot-password-btn"
                  >
                    Verify OTP
                  </button>
                )}
            </>
          )}
          <div className="text-left my-5">
            <h4 className="text-color-Exdark">
              Unlock your account in two easy steps:
            </h4>
            <p>
              <strong>Step1:</strong> Verify your email to get started.
            </p>
            <p>
              <strong>Step2:</strong> Complete your details to finalize your
              signup.
            </p>
          </div>
        </form>
      )}
      {(message === "OTP verified successfully" ||
        message === "Password updated successfully") && (
        <form
          className="sign-up-form needs-validation"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <h2 className="title">Sign Up</h2>
          <div className="input-field">
            <i className="fas fa-user"></i>
            <input
              type="text"
              className={`form-control ${errors.username && "is-invalid"}`}
              placeholder="Username"
              name="username"
              value={formData.username}
              onChange={handleInputChange}
              required
            />
            {errors.username && (
              <div className="invalid-feedback">{errors.username}</div>
            )}
          </div>
          <div className="input-field">
            <i className="fas fa-lock"></i>
            <input
              type="password"
              className={`form-control ${errors.password && "is-invalid"}`}
              placeholder="Password"
              name="password"
              value={formData.password}
              onChange={handleNewPasswordChange}
              minLength="6"
              autoComplete="off"
              required
            />
            {errors.password && (
              <div className="invalid-feedback">{errors.password}</div>
            )}
          </div>
          <div className="input-field">
            <i className="fas fa-lock"></i>
            <input
              type="password"
              className={`form-control ${
                errors.confirmPassword && "is-invalid"
              }`}
              placeholder="Confirm Password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleConfirmPasswordChange}
              minLength="6"
              autoComplete="off"
              required
            />
            {errors.confirmPassword && (
              <div className="invalid-feedback">{errors.confirmPassword}</div>
            )}
          </div>
          {errors.serverError && (
            <div className="text-danger">{errors.serverError}</div>
          )}
          <input
            type="submit"
            className="btn sign-btn"
            value="Sign up"
            name="signup"
          />
        </form>
      )}
    </>
  );
}

export default SignUp;
