import React,{ useEffect, useState } from "react";
import "./WhatWeDo.css";
import { Link } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
function WhatWeDo() {
  
  const [email, setEmail] = useState("");
  const [internId, setInternId] = useState(""); 

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      setEmail(decodedToken.email);
      setInternId(decodedToken.internid);
    }
  }, []);

  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center container my-20">
        <div className="d-flex flex-column align-items-center justify-content-center ">
          <p className="text-uppercase text-color-dark">What we do</p>
          <h1 className="mb-4 mx-10 text-center fs-1 text-color-lg">
            {/* CodeCrafters: */}
            <span className="text-color-Exdark">Crafting Digital </span>
            Destinies
            <span className="text-color-Exdark"> & </span>
            <span className="text-color-Exdark"> Fostering Future </span>
            Talent
          </h1>
        </div>

        <div
          className="row row-cols-1 row-cols-md-2 g-5 my-8 "
          style={{ width: "90vw" }}
        >
          <div className="col d-flex justify-content-center">
            <div className="card rounded-3" id="doCards">
              <img
                src="https://source.unsplash.com/nPnIOAM8pbo/200x200&auto=format&fit=crop&&q=50"
                className="card-img rounded-3"
                alt="..."
                style={{ height: "60vh" }}
              />
              <div
                className="card-img-overlay card-overlay flex-column rounded-3"
                id="card-overlay2"
              >
                <Link to={`/Services#ourServices`}>
                  <small>Click Here</small>
                  <div id="card-title-after">
                    <h5 className="card-title">
                      Unlock Online Success: Elevate Your Business Presence
                      Today
                    </h5>
                  </div>
                </Link>
                <p className="card-text text-center">
                  Transforming ideas into captivating digital experiences –
                  Let's bring your vision to life!
                </p>
              </div>
            </div>
          </div>
          <div className="col d-flex justify-content-center">
            <div className="card rounded-3" id="doCards">
              <img
                src="https://source.unsplash.com/FlPc9_VocJ4/200x200&auto=format&fit=crop&&q=50"
                className="card-img rounded-3"
                alt="..."
                style={{ height: "60vh" }}
              />
              <div
                className="card-img-overlay card-overlay rounded-3 flex-column"
                id="card-overlay2"
              >
                {
                  !email ? (
                    <Link to={`/Sign`}>
                      <small>Click Here</small>
                      <div id="card-title-after">
                        <h5 className="card-title">Lets Start Your Internship</h5>
                      </div>
                    </Link>
                  ) : (
                    <Link to={internId ? `/User-Dashboard` : `/Apply-For-Internship`}>
                      <small>Click Here</small>
                      <div id="card-title-after">
                        <h5 className="card-title">Lets Start Your Internship</h5>
                      </div>
                    </Link>
                  )
                }
                <p className="card-text text-center">
                  Fuel your curiosity and ignite your career with our dynamic
                  internship opportunities – Join us and unlock your potential.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WhatWeDo;
