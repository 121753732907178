import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import HeroSection2 from "../components/HeroSection2";
import "../App.css";

// function HireUs
function HireUs() {
  return (
    <>
      <Navbar />
      <HeroSection2
        pageTitle="Hire Us"
        pageSubtitle="Get in touch with us for working with us"
      />
      <div className="card">
        <div className="card-body">
          <h1 className="text-center">Coming Soon</h1>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default HireUs;
