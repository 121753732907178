import React, { useState, useRef, useEffect } from "react";
import "./ContactForm.css";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";

function ContactForm() {
  const navigate = useNavigate();
  const formRef = useRef(null);
  const [userEmail, setUserEmail] = useState("");
  const [errors, setErrors] = useState("");

  // current Data and time
  const currentDate = new Date();
  const offset = currentDate.getTimezoneOffset(); // Get timezone offset in minutes
  currentDate.setMinutes(currentDate.getMinutes() - offset); // Adjust for timezone offset

  // Format the date and time as a MySQL-compatible string
  const formatedDate = currentDate.toISOString().slice(0, 19).replace("T", " ");

  // Define state to store form data
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    message: "",
    email: "",
    date: formatedDate,
  });

  // Handle changes in form input fields
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      setUserEmail(decodedToken.email);
      setFormData((prevData) => ({
        ...prevData,
        email: decodedToken.email, // Set email field in formData when token is available
      }));
    }
  }, []);

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = formRef.current;

    if (userEmail) {
      if (form.checkValidity() === false) {
        form.classList.add("was-validated");
      } else {
        try {
          const response = await axios.post(
            "https://practicoit.in/Server/Contactform/contactform.php/contactform/save",
            formData
          );
          if (response.data.status === 0) {
            setErrors(response.data.error);
          }
          setFormData({
            name: "",
            phone: "",
            message: "",
            email: "",
            date: formatedDate,
          });
        } catch (error) {
          console.error("Error submitting form:", error);
        }
      }
    } else {
      navigate("/sign"); // Use navigate instead of window.location for navigation
    }
  };

  return (
    <div className="ContactContainer">
      <div className="form">
        <div className="contact-info">
          <h3 className="title">Let's get in touch</h3>
          <p className="text">
            Need assistance or have a query? Contact us today! Our team is ready
            to help you with any questions or feedback you have.
          </p>

          <div className="info">
            <div className="information">
              <i className="fas fa-envelope"></i>{" "}
              <a href="mailto:official@practicoit.in">
                <p>official@practicoit.in</p>
              </a>
            </div>
            <div className="information">
              <i className="fas fa-phone"></i>{" "}
              <a href="tel:9911859914">
                <p>9911859914</p>
              </a>
            </div>
          </div>

          <div className="social-media">
            <p>Connect with us :</p>
            <div className="social-icons">
              <a href="./">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="./">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="./">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="./">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
        </div>

        <div className="contact-form">
          <span className="circle one"></span>
          <span className="circle two"></span>

          <form
            ref={formRef}
            onSubmit={handleSubmit}
            autoComplete="off"
            noValidate
          >
            <h3 className="title">Contact us</h3>
            {/* Name */}
            <div className="input-container">
              <input
                type="text"
                className="form-control"
                id="validationCustom03"
                placeholder="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              <div className="invalid-feedback">
                Please provide a valid name.
              </div>
            </div>
            {/* Email */}
            <div className="input-container">
              <input
                type="email"
                className="form-control"
                id="validationCustomEmail"
                name="email"
                value={userEmail}
                readOnly
                onChange={handleChange}
                placeholder="Email"
                required
              />
              <div className="invalid-feedback">
                Please provide a valid email address.
              </div>
            </div>
            {/* Phone number */}
            <div className="input-container">
              <input
                type="tel"
                className="form-control"
                id="validationCustomPhone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                pattern="[0-9]{10}"
                placeholder="Phone No."
                required
              />
              <div className="invalid-feedback">
                Please provide a valid phone number.
              </div>
            </div>
            {/* Message */}
            <div className="input-container textarea">
              <textarea
                id="message"
                className="form-control"
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Message"
                maxLength={500}
                rows={5}
                required
              ></textarea>
              <div className="invalid-feedback">Please enter a message.</div>
            </div>
            {/* Error messages */}
            {errors !== "" ? (
              <div
                className="alert alert-danger alert-dismissible fade show m-2 h-10 d-flex align-items-center"
                role="alert"
              >
                <strong>Alert! </strong> {errors}
              </div>
            ) : (
              ""
            )}
            <button className="ContactBtn btn ">Send</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
