import React, { useEffect } from "react";
import HeroSection2 from "../components/HeroSection2";
import ContactForm from "../components/ContactForm";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../App.css";

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 500); // Scroll to the top when component mounts
  }, []);

  return (
    <>
      <Navbar />
      <HeroSection2
        pageTitle="Contact"
        pageSubtitle="Feel free to keep in touch"
      />
      <ContactForm />
      <Footer />
    </>
  );
}

export default Contact;
