import React, { useEffect, useState } from "react";
import "../Pages/UserDashboard.css";
import DashNavbar from "./DashNavbar";
import DashSidebar from "./DashSidebar";
import { jwtDecode } from "jwt-decode";
import axios from "axios";

function Status() {
  const [internId, setInternId] = useState("");
  const [data, setData] = useState([]);
  const [statusId, setStatusId] = useState("");
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    internId: "",
    statusId: "",
    projectTitle: "",
    projectDescription: "",
    projectImage: "",
  });
 
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      setInternId(decodedToken.internid);
      const generateStatusId = decodedToken.internid + new Date().getDate();
      setStatusId(generateStatusId);
      fetchData(decodedToken.internid);
    }
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // ##### Date and Time #####

   // current Data and time
   const currentDate = new Date();
   const offset = currentDate.getTimezoneOffset(); // Get timezone offset in minutes
   currentDate.setMinutes(currentDate.getMinutes() - offset); // Adjust for timezone offset
 
   // Format the date and time as a MySQL-compatible string
   const formatedDate = currentDate.toISOString().slice(0, 19).replace("T", " ");

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setFormData({
      ...formData,
      projectImage: file,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append("internId", internId);
    formDataToSend.append("statusId", statusId);
    formDataToSend.append("projectTitle", formData.projectTitle);
    formDataToSend.append("projectDescription", formData.projectDescription);
    formDataToSend.append("projectImage", formData.projectImage);
    formDataToSend.append("currentDate", formatedDate);

    try {
      const response = await axios.post(
        "https://practicoit.in/Server/UserDashboard/insertStatus.php/Status/save",
        formDataToSend
      );
      if (response.data.status === 0) {
        setErrors({ serverError: response.data.error });
      } else {
        setFormData({
          projectTitle: "",
          projectDescription: "",
          projectImage: "",
        });
        fetchData(internId);
        console.log("Form submitted successfully:", response.data);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const fetchData = async (InternId) => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/UserDashboard/getStatusData.php/getstatusdata",
        { InternId }
      );
      setData(response.data);
      console.log(response.data);

    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const [enlargedImageSrc, setEnlargedImageSrc] = useState(null);
  const [showEnlargedImage, setShowEnlargedImage] = useState(false);

  const enlargeImage = (clickedImgSrc) => {
    setEnlargedImageSrc(clickedImgSrc);
    setShowEnlargedImage(true);
  };

  const closeEnlargedImage = () => {
    setShowEnlargedImage(false);
  };

  return (
    <>
      <div id="Dashboard-Main">
        <DashNavbar />
        <div id="wrapper">
          <DashSidebar />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <div className="container-fluid">
                <div>
                  <h2 className="heading p-3"> Submit Project Status </h2>
                  <div className="card customForm">
                    <form
                      action=""
                      autoComplete="off"
                      onSubmit={handleSubmit}
                      encType="multipart/form-data"
                    >
                      <div className="card-body">
                        <input
                          type="hidden"
                          className="form-control"
                          name="internId"
                          value={internId}
                        />
                        <input
                          type="hidden"
                          className="form-control"
                          name="internId"
                          value={statusId}
                        />
                        <div className="form-group mb-3">
                          <label htmlFor="project-title" className="form-label">
                            Project Title
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="project-title"
                            onChange={handleChange}
                            name="projectTitle"
                            placeholder="Project Title"
                            required
                          />
                        </div>
                        <div className="form-group mb-3">
                          <label
                            htmlFor="project-description"
                            className="form-label"
                          >
                            Project Description
                          </label>
                          <textarea
                            className="form-control"
                            name="projectDescription"
                            onChange={handleChange}
                            id="project-description"
                            rows="6"
                            required
                          ></textarea>
                        </div>
                        <div className="form-group mb-3">
                          <label htmlFor="project-image" className="form-label">
                            Project Image
                          </label>
                          <input
                            type="file"
                            name="projectImage"
                            className="form-control"
                            onChange={handleImageChange}
                            id="project-image"
                            required
                          />
                        </div>
                      </div>
                      {errors.serverError && (
                        <div
                          className="alert alert-danger mx-6 mb-4"
                          role="alert"
                        >
                          {errors.serverError && <p>{errors.serverError}</p>}
                        </div>
                      )}
                      <div className="mb-4 mx-7">
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                  {/* Previous Updates Section */}
                  <h2 className="heading p-3"> Previous Updates </h2>
                  <div className="card customCard mb-3" style={{ overflow: "hidden" }}>
                    <div className="card-body previousUpdatesTable">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col" className="fs-6">
                              Date & Time
                            </th>
                            <th scope="col" className="fs-6">
                              Project Title
                            </th>
                            <th scope="col" className="fs-6">
                              Project Description
                            </th>
                            <th scope="col" className="fs-6">
                              Project Image
                            </th>
                            {/* <th scope="col" className="fs-6">
                              Update
                            </th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {Array.isArray(data) && data.length > 0 ? (
                            data.map((item, index) => (
                              <tr key={index}>
                                <td>{item.created_at}</td>
                                <td>{item.projectTitle}</td>
                                <td>{item.projectDescription}</td>
                                <td>
                                  <div className="smallImage-container">
                                    <img
                                      src={`https://practicoit.in/Server/UserDashboard/${item.projectImage}`}
                                      className="thumbnail"
                                      alt={`${item.projectTitle}`}
                                      onClick={() =>
                                        enlargeImage(
                                          `https://practicoit.in/Server/UserDashboard/${item.projectImage}`
                                        )
                                      }
                                      style={{
                                        cursor: "pointer",
                                        transition: "transform 0.2s  linear",
                                        width: "100px",
                                        height: "auto",
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="5">No data available</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Enlarged image container */}
      {showEnlargedImage && (
        <div className="enlargedImageContainer" onClick={closeEnlargedImage}>
          <div className="enlargeInnerContainer">
            <div className="enlarged-image">
              <img
                src={enlargedImageSrc}
                id="enlarged-img"
                alt="Enlarged"
              />
            </div>
          </div>
        </div>
      )}

    </>
  );
}

export default Status;
