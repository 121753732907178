import React, { useEffect, useState } from "react";
import "../App.css";
import HeroSection2 from "../components/HeroSection2";
import AboutSection from "../components/AboutSection";
import FAQs from "../components/FAQs";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import axios from "axios";
import ServiceCards from "../components/ServiceCards";
import web from "../images/web.png";
import app from "../images/app.png";
// import software from "../images/software.png";
import intern from "../images/intern.png";
import services from "../images/services.png";

function Services() {
  const [content, setContent] = useState("");
  const [subHeading, setSubHeading] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
    getuserdata("About Services");
  }, []);

  const getuserdata = async (sectionName) => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/WebContent/fetchWebComponent.php",
        { sectionName }
      );
      setContent(response.data[0]?.Description || "");
      setSubHeading(response.data[0]?.subHeading || "");
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  return (
    <>
      <Navbar />
      <HeroSection2
        pageTitle="Services"
        pageSubtitle="Bridge for industrial and corporate development"
      />
      <AboutSection
        display="d-none"
        secTitle="About Services"
        subHeading={subHeading}
        content={content}
        Img={services}
      />
      <div className="ourServices mb-10" id="ourServices">
        <small className="text-uppercase text-color-sm">Our Services</small>
        <h1 className="text-color-lg d-flex flex-column  align-items-center text-center">
          Explore Our Comprehensive Range of Services
        </h1>
        <div className="service-cards">
          <ServiceCards
            img={intern}
            serviceType="Internship Program"
            content="In this program, we provide internships to enhance practical skills and career readiness through hands-on experience and mentorship."
          />
          <ServiceCards
            img={web}
            serviceType="Web Development"
            content="We specialize in web development, creating professional, responsive websites tailored to our clients' needs."
          />
          <ServiceCards
            img={app}
            serviceType="App Development"
            content="We specialize in app development, creating custom, user-friendly mobile applications tailored to our clients' needs."
          />
          {/* <ServiceCards img={software} serviceType="Software Development" /> */}
        </div>
      </div>
      <FAQs />
      <Footer />
    </>
  );
}

export default Services;
