import React, { useEffect, useState } from "react";
import "./ClientTestimonial.css"; // Add CSS for custom styling
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import axios from "axios";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Navigation } from "swiper/modules";

function ClientTestimonial() {
  const [swiper, setSwiper] = useState(null);

  const handleMouseEnter = () => {
    if (swiper) {
      swiper.autoplay.stop();
    }
  };

  const handleMouseLeave = () => {
    if (swiper) {
      swiper.autoplay.start();
    }
  };

  useEffect(() => {
    fetchClientTestimonial();
  }, []);

  const [clientTData, setClientTData] = useState();

  const fetchClientTestimonial = async () => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/WebContent/fetchClientTestimonial.php/clientTestimonial"
      );
      // console.log(response.data);
      setClientTData(response.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  return (
    <>
      <>
        <section className="testimonial_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="about_content">
                  <div className="background_layer"></div>
                  <div className="layer_content">
                    <div className="section_title">
                      <h5>CLIENTS</h5>
                      <h2>
                        Happy with<strong>Customers & Clients</strong>
                      </h2>
                      <div className="heading_line">
                        <span></span>
                      </div>
                      <p>
                        Discover what our clients have to say about their
                        experience with us. Read testimonials from satisfied
                        customers and learn how we've helped them achieve their
                        goals.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-5">
                <div className="testimonial_box">
                  <div
                    className="testimonial_container"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="background_layer"></div>
                    <div className="layer_content">
                      <Swiper
                        spaceBetween={30}
                        slidesPerView={1}
                        loop={true}
                        autoplay={{
                          delay: 5000,
                          disableOnInteraction: false,
                        }}
                        navigation={true}
                        modules={[Autoplay, Navigation]}
                        className="mySwiper mt-10 mb-20"
                        breakpoints={{
                          768: {
                            slidesPerView: 1,
                          },
                          576: {
                            slidesPerView: 1,
                          },
                          320: {
                            slidesPerView: 1,
                          },
                        }}
                        onSwiper={setSwiper}
                      >
                        {Array.isArray(clientTData) &&
                        clientTData.length > 0 ? (
                          clientTData.map((item, index) => (
                            <SwiperSlide key={index}>
                              <div className="testimonials">
                                <div className="testimonial_content">
                                  <div className="testimonial_caption">
                                    <h6>{item.clientName}</h6>
                                    <span>{item.profession}</span>
                                  </div>
                                  <p>{item.comment}</p>
                                </div>
                                {/*  */}
                                <div className="testimonial_img">
                                  <img
                                    className=""
                                    src={`https://practicoit.in/Server/Admin/${item.clientImage}`}
                                    alt="images not found"
                                  />
                                  {/* </div> */}
                                </div>
                              </div>
                            </SwiperSlide>
                          ))
                        ) : (
                          <SwiperSlide>
                            <div className="testimonials">
                              <div className="testimonial_content">
                                <div className="testimonial_caption">
                                  <h6>Amit</h6>
                                  <span>CEO, PrimeEducation</span>
                                </div>
                                <p>
                                  The team at Tectxon industry is very talented,
                                  dedicated, well organized and knowledgeable. I
                                  was most satisfied with the quality of the
                                  workmanship. They did excellent work.
                                </p>
                              </div>

                              <div className="testimonial_img">
                                <img
                                  className=""
                                  src="https://source.unsplash.com/QXevDflbl8A/200x200&auto=format&fit=crop&&q=50"
                                  alt="images not found"
                                />
                              </div>
                            </div>
                          </SwiperSlide>
                        )}

                        {/* Add more testimonials as needed */}
                      </Swiper>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </>
  );
}

export default ClientTestimonial;
