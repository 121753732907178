import React from "react";
import "../Pages/UserDashboard.css";
import DashNavbar from "./DashNavbar";
import DashSidebar from "./DashSidebar";

function Courses() {
  return (
    <>
      <div id="Dashboard-Main">
        <DashNavbar />
        <div id="wrapper">
          <DashSidebar />
          {/* Main Content */}
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <div className="container-fluid">
                {/* Courses */}

                <div className="courses my-5">
                  <h2 className="heading py-3">Courses Playlist</h2>
                  <div className="Courses-card">
                    <div className="card mb-3 ">
                      <div className="row g-0 ">
                        <div className="col-md-4  overflow-hidden">
                          <img
                            src="https://source.unsplash.com/ltpb_WinC3Y/200x200&auto=format&fit=crop&&q=50"
                            className="card-img-top rounded-3"
                            alt="..."
                            style={{ height: "100%", objectFit: "cover" }}
                          />
                        </div>
                        <div className="col-md-8">
                          <div className="card-body">
                            <h3 className="heading mt-0 py-2 rounded-1">
                              HTML5 Course Playlist
                            </h3>
                            <p className="card-text">
                              This is a wider card with supporting text below as
                              a natural lead-in to additional content. This
                              content is a little bit longer.
                            </p>
                            <button type="button" className="btn mt-2">
                              Explore
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card mb-3">
                      <div className="row g-0">
                        <div className="col-md-4 overflow-hidden">
                          <img
                            src="https://source.unsplash.com/ipARHaxETRk/200x200&auto=format&fit=crop&&q=50"
                            className="card-img-top rounded-3"
                            alt="..."
                            style={{ height: "100%", objectFit: "cover" }}
                          />
                        </div>
                        <div className="col-md-8">
                          <div className="card-body">
                            <a href="/User-Dashboard/CoursesVideos">
                              <h3 className="heading mt-0 py-2 rounded-1">
                                CSS Course Playlist
                              </h3>
                            </a>
                            <p className="card-text">
                              This is a wider card with supporting text below as
                              a natural lead-in to additional content. This
                              content is a little bit longer.
                            </p>
                            <button type="button" className="btn mt-2">
                              Explore
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card mb-3">
                      <div className="row g-0">
                        <div className="col-md-4 overflow-hidden">
                          <img
                            src="https://source.unsplash.com/vw3Ahg4x1tY/200x200&auto=format&fit=crop&&q=50"
                            className="card-img-top rounded-3"
                            alt="..."
                            style={{ height: "100%", objectFit: "cover" }}
                          />
                        </div>
                        <div className="col-md-8">
                          <div className="card-body">
                            <h3 className="heading mt-0 py-2 rounded-1">
                              JavaScript Course Playlist
                            </h3>
                            <p className="card-text">
                              This is a wider card with supporting text below as
                              a natural lead-in to additional content. This
                              content is a little bit longer.
                            </p>
                            <button type="button" className="btn mt-2">
                              Explore
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Courses;
