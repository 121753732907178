import React, { useEffect, useState } from "react";
import "./BlogSection.css";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import axios from "axios";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";
import BlogCard from "./BlogCard";

function BlogSection() {
  const [data, setData] = useState([]);
  const [swiper, setSwiper] = useState(null);

  const handleMouseEnter = () => {
    if (swiper) {
      swiper.autoplay.stop();
    }
  };

  const handleMouseLeave = () => {
    if (swiper) {
      swiper.autoplay.start();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/WebContent/FetchBlogs.php/fetchblog"
      );
      setData(response.data);
    } catch (error) {
      console.error("Error fetching inbox data:", error);
    }
  };

  return (
    <>
      <section className="BlogSection ">
        <div className="BlogSlides">
          <div
            className="mx-10"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <span className="text-color-dark text-color-sm"> Blogs</span>
            <h1 className=" fs-1 text-color-lg">
              Explore Our
              <span className="text-color-Exdark"> Blogs</span>
            </h1>
          </div>
          <Swiper
            spaceBetween={30}
            slidesPerView={2}
            loop={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            navigation={true}
            modules={[Autoplay, Navigation]}
            className="mySwiper mt-10 mb-20"
            breakpoints={{
              768: {
                slidesPerView: 2,
              },
              576: {
                slidesPerView: 1,
              },
              320: {
                slidesPerView: 1,
              },
            }}
            onSwiper={setSwiper}
          >
            {Array.isArray(data) && data.length > 0
              ? data.map((item, index) => (
                  <SwiperSlide key={index}>
                    <Link to={`/Blog/${item.BlogHeading.replace(/\s+/g, "-")}`}>
                      <BlogCard
                        title={
                          item.BlogHeading ? item.BlogHeading.slice(0, 35) : ""
                        }
                        content={
                          item.BlogContent ? item.BlogContent.slice(0, 100) : ""
                        }
                        imgUrl={item.BlogImage}
                        createdAt={item.created_at}
                      />
                    </Link>
                  </SwiperSlide>
                ))
              : null}
          </Swiper>
        </div>
      </section>
    </>
  );
}

export default BlogSection;
