import React, { useEffect, useState } from "react";
import {jwtDecode} from "jwt-decode";
import axios from "axios";
import DashNavbar from "../dashboardComponent/DashNavbar";
import DashSidebar from "../dashboardComponent/DashSidebar";
import "./UserDashboard.css";
import Loader from "../components/Loader";
import "../components/Loader.css";

function UserDashboard() {
  const [name, setName] = useState("");
  const [plan, setPlan] = useState("");
  const [project, setProject] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [batchId, setBatchId] = useState("");
  const [joiningDateTime, setJoiningDateTime] = useState("");
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [countdownMessage, setCountdownMessage] = useState(""); // State to store countdown message
  const [loading, setLoading] = useState(true); // Loader state
  const [data, setData] = useState(""); // State to store session data

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      getprojectdata(decodedToken.internid);
      getuserdata(decodedToken.internid);
    }
  }, []);

  const getuserdata = async (InternId) => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/InternshipApplication/getUserData.php/getUserdata",
        { InternId: InternId }
      );
      setName(response.data.Firstname);
      setPlan(response.data.SelectedPlan);
      setJoiningDateTime(response.data.selectedat);
      setBatchId(response.data.BatchId);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const getprojectdata = async (InternId) => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/UserDashboard/projectData.php/getdata",
        { InternId }
      );
      setProject(response.data.projects[0]);
      setProjectDescription(
        response.data.projects[0].ProjectDescription.replaceAll("\\n", "<br>").replaceAll("\\", "")
      );
    } catch (error) {
      // console.error("Error fetching project data:", error);
    }
  };

  // ########### CountDown Timer for intern's last date ##########
  useEffect(() => {
    if (!joiningDateTime || !plan) {
      return;
    }

    const calculateEndDate = (joiningDateTime, planType) => {
      const totalDays = planType === "Premium" ? 45 : 30;
      const firstCountdownDays = planType === "Premium" ? 15 : 0; // First countdown days for Premium plan

      const firstPhaseEndDate = new Date(joiningDateTime);
      firstPhaseEndDate.setDate(firstPhaseEndDate.getDate() + firstCountdownDays);

      const secondPhaseEndDate = new Date(joiningDateTime);
      secondPhaseEndDate.setDate(secondPhaseEndDate.getDate() + totalDays);

      return { firstPhaseEndDate, secondPhaseEndDate, firstCountdownDays };
    };

    const { firstPhaseEndDate, secondPhaseEndDate } = calculateEndDate(joiningDateTime, plan);

    const calculateTimeLeft = (endDate) => {
      const difference = +endDate - +new Date();
      if (difference > 0) {
        return {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        };
      } else {
        return { days: 0, hours: 0, minutes: 0, seconds: 0 };
      }
    };

    const updateTimer = () => {
      const now = new Date();
      let timeLeft, message;

      if (plan === "Premium" && now < firstPhaseEndDate) {
        timeLeft = calculateTimeLeft(firstPhaseEndDate);
        message = "Training";
      } else {
        timeLeft = calculateTimeLeft(secondPhaseEndDate);
        message = "Internship";
      }

      setTimeLeft(timeLeft);
      setCountdownMessage(message);
    };

    // Update the timer immediately upon rendering
    updateTimer();

    // Update the timer every second
    const interval = setInterval(updateTimer, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [joiningDateTime, plan]);

  // ################# Fetch sessions ###########
  useEffect(() => {
    if (batchId) {
      fetchData(batchId);
    }
  }, [batchId]);

  const fetchData = async (batchId) => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/Admin/fetchSession.php/fetchSession",
        { batchId: batchId }
      );
      setData(response.data);
    } catch (error) {
      console.error("Error fetching session data:", error);
    }
  };

  useEffect(() => {
    // Simulate an async operation
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div id="Dashboard-Main">
          <DashNavbar />
          <div id="wrapper">
            <DashSidebar />
            <div id="page-content-wrapper">
              <div className="container-fluid">
                <div className="container-fluid">
                  <div className="row py-3">
                    <div className="col col-md-7 my-5">
                      <div className="card introCard">
                        <div className="card-body d-flex justify-content-between align-items-center flex-wrap">
                          <div>
                            <h1>Welcome, {name}</h1>
                            <h2>Plan : {plan}</h2>
                          </div>
                          <i className="fa-solid fa-user-tie icon-size"></i>
                        </div>
                      </div>
                    </div>
                    <div className="col col-md-5 my-5">
                      <div className="card introCard">
                        <div className="card-body d-flex justify-content-between align-items-center">
                          <div>
                            <h1>{countdownMessage}</h1>
                            <h2>Days Left</h2>
                          </div>
                          <div>
                            <h2 className="icon-size">{timeLeft.days + 1}</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Session Schedule */}

                  <div className="session-schedule">
                    <h2 className="heading py-3">Session Schedule</h2>
                    <div className="container Charged">
                      <div className="row">
                        {Array.isArray(data) && data.length > 0 ? (
                          data.map((item, index) => (
                            <div className="card" key={index}>
                              <div className="card-body">
                                <h4 className="card-title my-1">{item.sessionTopic}</h4>
                                <p className="d-flex justify-content-between">
                                  <span>{item.sessionDate}</span>
                                  <span>{item.sessionTime}</span>
                                </p>
                                {item.sessionLink !== "Not Alloted" ? (
                                  <a href={item.sessionLink} className="btn btn-dark my-2">
                                    Join Session
                                  </a>
                                ) : (
                                  <button className="btn btn-dark my-2" disabled>
                                    Coming Soon...
                                  </button>
                                )}
                              </div>
                            </div>
                          ))
                        ) : (
                          <p className="d-flex align-item-center">No Session is Scheduled.</p>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Project Description */}

                  <div className="project-description">
                    <h2 className="heading py-3">Project Description</h2>
                    <div className="card customCard p-10">
                      {project === "" ? (
                        <p className="text-center">No Project Allotted</p>
                      ) : (
                        <>
                          <h3 className="text-color-Exdark my-3">{project.ProjectName}</h3>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: projectDescription,
                            }}
                          ></p>
                        </>
                      )}
                    </div>
                  </div>

                  {/* Courses */}

                  {/* <div className="courses my-5">
                    <h2 className="heading py-3">Courses</h2>
                    <div className="row">
                      <div className="col col-md-4">
                        <div className="card" style={{ width: "18rem", height: "17rem" }}>
                          <img
                            src="https://source.unsplash.com/ltpb_WinC3Y/200x200&auto=format&fit=crop&&q=50"
                            className="card-img-top"
                            alt="..."
                            style={{ height: "180px", objectFit: "cover" }}
                          />
                          <div className="card-body">
                            <h3>HTML5 Course Playlist</h3>
                          </div>
                        </div>
                      </div>
                      <div className="col col-md-4">
                        <div className="card" style={{ width: "18rem", height: "17rem" }}>
                          <img
                            src="https://source.unsplash.com/ipARHaxETRk/200x200&auto=format&fit=crop&&q=50"
                            className="card-img-top"
                            alt="..."
                            style={{ height: "180px", objectFit: "cover" }}
                          />
                          <div className="card-body">
                            <h3>CSS Course Playlist</h3>
                          </div>
                        </div>
                      </div>
                      <div className="col col-md-4">
                        <div className="card" style={{ width: "18rem", height: "17rem" }}>
                          <img
                            src="https://source.unsplash.com/vw3Ahg4x1tY/200x200&auto=format&fit=crop&&q=50"
                            className="card-img-top"
                            alt="..."
                            style={{ height: "180px", objectFit: "cover" }}
                          />
                          <div className="card-body">
                            <h3>JavaScript Course Playlist</h3>
                          </div>
                        </div>
                      </div>
                      <div className="my-3 d-flex justify-content-center">
                        <a href="User-Dashboard/Plan" className="btn m-3">
                          Switch To Premium Plan
                        </a>
                        <a href="User-Dashboard/courses" className="btn m-3">
                          Explore More Courses
                        </a>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default UserDashboard;
