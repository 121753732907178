import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import "./App.css";
import Loader from "./components/Loader";
import "./components/Loader.css";
function App() {
    // loader;
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      // Simulate an async operation
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }, []);
  
  return (
    <>
    {loading ? (
      <Loader/>
    ) : (
   
    <div className="App">
      <main>
        <Outlet />
      </main>
    </div>
   )} 
      </>
  );
}

export default App;
