import React, { useRef, useState, useEffect } from "react";
import "../components/MultiStepForm.css"; // CSS file
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import "../Pages/UserDashboard.css";
import DashNavbar from "./DashNavbar";
import DashSidebar from "./DashSidebar";

function Profile() {
  const formRef = useRef(null);
  // const [InternId, setInternId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [contitions, setConditions] = useState(false);
  const [formData, setFormData] = useState({
    internid: "",
    firstName: "",
    lastName: "",
    gender: "",
    internship: "",
    course: "",
    email: "",
    phone: "",
    state: "",
    collegeName: "",
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      // setInternId(decodedToken.internid);
      fetchData(decodedToken.internid);
    }
  }, []);

  const fetchData = async (InternId) => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/InternshipApplication/getUserData.php/getUserData",
        { InternId }
      );
      const userData = response.data;
      setFormData({
        internid: InternId,
        firstName: userData.Firstname,
        lastName: userData.Lastname,
        gender: userData.Gender,
        internship: userData.InternshipDoneBefore,
        course: userData.Course,
        email: userData.Email,
        phone: userData.Phone,
        state: userData.State,
        collegeName: userData.CollegeName,
      });
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setConditions(true);
  };

  // console.log(contitions);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (contitions) {
        const response = await axios.post(
          "https://practicoit.in/Server/UserDashboard/updateUserDetails.php/update",
          formData
        );

        console.log("Form submitted successfully:", response.data.message);
        if (response.data.status === 1) {
          setShowModal(true);
          setConditions(false);
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <>
          <div
            className="modal fade show "
            tabIndex="-1"
            role="dialog"
            style={{ display: "block", backgroundColor: "#242f9b6e" }}
          >
            <div
              className="modal-dialog modal-dialog-centered "
              role="document"
            >
              <div
                className="modal-content text-light"
                style={{ backgroundColor: "#11164f" }}
              >
                <div className="modal-header">
                  <h5 className="modal-title text-light">Success</h5>
                  <button
                    type="button"
                    className="close d-flex align-items-center"
                    aria-label="Close"
                    onClick={closeModal}
                    style={{ color: "#ba0000", backgroundColor: "transparent" }}
                  >
                    <i className="fa-solid fa-circle-xmark fs-5"></i>
                    {/* <i className="fa-solid fa-square-xmark"></i> */}
                  </button>
                </div>
                <div className="modal-body">
                  Your form has been submitted successfully!
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div id="Dashboard-Main">
        <DashNavbar />
        <div id="wrapper">
          <DashSidebar />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <div className="container-fluid">
                <h2 className="heading p-3">Profile</h2>
                <div className="multisteps-form">
                  <div className="my-20 shadow mx-auto ContactContainer internship-form">
                    <form
                      ref={formRef}
                      className="row g-10"
                      noValidate
                      autoComplete="off"
                      onSubmit={handleSubmit}
                    >
                      {/* InternId */}
                      <input
                        type="hidden"
                        name="internid"
                        onChange={handleChange}
                        value={formData.internid}
                      />

                      {/* First Name */}
                      <div className="col-md-6">
                        <label
                          htmlFor="validationCustom01"
                          className="form-label"
                        >
                          First name
                          <span style={{ color: "red" }}> * </span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="validationCustom01"
                          name="firstName"
                          value={formData.firstName}
                          onChange={handleChange}
                          placeholder="First name"
                          required
                        />
                      </div>

                      {/* Last Name */}
                      <div className="col-md-6">
                        <label
                          htmlFor="validationCustom02"
                          className="form-label"
                        >
                          Last name
                          <span style={{ color: "red" }}> * </span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="validationCustom02"
                          name="lastName"
                          value={formData.lastName}
                          onChange={handleChange}
                          placeholder="Last name"
                          required
                        />
                      </div>

                      {/* Gender */}
                      <div className="col-md-5">
                        <label
                          htmlFor="validationCustomGender"
                          className="form-label"
                        >
                          Gender
                          <span style={{ color: "red" }}> * </span>
                        </label>
                        <div className="d-flex">
                          {/* Gender radio buttons */}
                          <div className="form-check mx-2">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="validationCustomGenderMale"
                              name="gender"
                              value="male"
                              checked={formData.gender === "male"}
                              onChange={handleChange}
                              required
                            />
                            <label
                              htmlFor="validationCustomGenderMale"
                              className="form-check-label"
                            >
                              Male
                            </label>
                          </div>
                          <div className="form-check mx-2">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="validationCustomGenderFemale"
                              name="gender"
                              value="female"
                              checked={formData.gender === "female"}
                              onChange={handleChange}
                              required
                            />
                            <label
                              htmlFor="validationCustomGenderFemale"
                              className="form-check-label"
                            >
                              Female
                            </label>
                          </div>
                          <div className="form-check mx-2">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="validationCustomGenderOthers"
                              name="gender"
                              value="Others"
                              checked={formData.gender === "Others"}
                              onChange={handleChange}
                              required
                            />
                            <label
                              htmlFor="validationCustomGenderOthers"
                              className="form-check-label"
                            >
                              Others
                            </label>
                          </div>
                        </div>
                        <div className="invalid-feedback">
                          Please select your gender.
                        </div>
                      </div>

                      {/* Internship */}
                      <div className="col-md-6">
                        <label className="form-label">
                          Have you enrolled in any other internship before?
                          <span style={{ color: "red" }}> * </span>
                        </label>
                        <div className="d-flex">
                          {/* Radio buttons for internship */}
                          <div className="form-check mx-2">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="yes"
                              name="internship"
                              value="yes"
                              checked={formData.internship === "yes"}
                              onChange={handleChange}
                              required
                            />
                            <label htmlFor="yes" className="form-check-label">
                              Yes
                            </label>
                          </div>
                          <div className="form-check mx-2">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="no"
                              name="internship"
                              value="no"
                              checked={formData.internship === "no"}
                              onChange={handleChange}
                              required
                            />
                            <label htmlFor="no" className="form-check-label">
                              No
                            </label>
                          </div>
                        </div>
                        <div className="invalid-feedback">
                          Please select an option.
                        </div>
                      </div>

                      {/* Course */}
                      <div className="col-md-6">
                        <label
                          htmlFor="validationCustomCourse"
                          className="form-label"
                        >
                          Course Name
                          <span style={{ color: "red" }}> * </span>
                        </label>
                        <select
                          className="form-select"
                          id="validationCustomCourse"
                          name="course"
                          value={formData.course}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Select Course</option>
                          <option value="BCA">BCA</option>
                          <option value="B.Tech">B.Tech</option>
                          <option value="MCA">MCA</option>
                          <option value="M.Tech">M.Tech</option>
                          <option value="BSc (Computer Science)">
                            BSc (Computer Science)
                          </option>
                          <option value="MSc (Computer Science)">
                            MSc (Computer Science)
                          </option>
                          <option value="Other">Other</option>
                        </select>
                        <div className="invalid-feedback">
                          Please select a course.
                        </div>
                      </div>

                      {/* Email */}
                      <div className="col-md-6">
                        <label
                          htmlFor="validationCustomEmail"
                          className="form-label"
                        >
                          Email<span style={{ color: "red" }}> * </span>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="validationCustomEmail"
                          name="email"
                          value={formData.email}
                          readOnly
                          required
                        />
                        <div className="invalid-feedback">
                          Please provide a valid email address.
                        </div>
                      </div>

                      {/* Phone number */}
                      <div className="col-md-6">
                        <label
                          htmlFor="validationCustomPhone"
                          className="form-label"
                        >
                          Phone number
                          <span style={{ color: "red" }}> * </span>
                        </label>
                        <input
                          type="tel"
                          className="form-control"
                          id="validationCustomPhone"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                          pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                          placeholder="1234567890"
                          required
                        />
                        <div className="invalid-feedback">
                          Please provide a valid phone number (e.g.,
                          1234567890).
                        </div>
                      </div>

                      {/* State */}
                      <div className="col-md-6">
                        <label
                          htmlFor="validationCustom05"
                          className="form-label"
                        >
                          State<span style={{ color: "red" }}> * </span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="validationCustom05"
                          name="state"
                          value={formData.state}
                          onChange={handleChange}
                          placeholder="Enter State name"
                          required
                        />
                        <div className="invalid-feedback">
                          Please provide State name{" "}
                        </div>
                      </div>

                      {/* College Name */}
                      <div className="col-md-12">
                        <label
                          htmlFor="validationCustom05"
                          className="form-label"
                        >
                          COLLEGE NAME (Full Name)
                          <span style={{ color: "red" }}> * </span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="validationCustom05"
                          name="collegeName"
                          value={formData.collegeName}
                          onChange={handleChange}
                          placeholder="Enter College Name"
                          required
                        />
                        <div className="invalid-feedback">
                          Please provide complete college name{" "}
                        </div>
                      </div>

                      <div className="button-row d-flex mt-4 col-12">
                        <button
                          className="btn btn-success ml-auto submit-btn"
                          type="submit"
                          title="Send"
                        >
                          Update
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
