import React, { useState, useEffect } from "react";
import "../Pages/UserDashboard.css";
import DashNavbar from "./DashNavbar";
import DashSidebar from "./DashSidebar";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

function Inbox() {
  const [data, setData] = useState([]);
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      fetchData(decodedToken.email);
    }
  }, []);

  const fetchData = async (email) => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/UserDashboard/getInboxData.php/getinboxdata",
        { email }
      );
      setData(response.data);
    } catch (error) {
      console.error("Error fetching inbox data:", error);
    }
  };

  const handleMarkAsRead = async (id) => {
    try {
      await axios.post(
        "https://practicoit.in/Server/UserDashboard/getInboxData.php/getinboxdata",
        { messageId: id }
      );

      console.log("Message marked as read:", id);
    } catch (error) {
      console.error("Error marking message as read:", error);
    }
  };

  return (
    <div id="Dashboard-Main">
      <DashNavbar />
      <div id="wrapper">
        <DashSidebar />
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <div className="container-fluid">
              <div className="inbox">
                <h2 className="heading p-3"> Inbox </h2>
                <div
                  className="accordion accordion-flush"
                  id="accordionFlushExample"
                >
                  {data ? data.map((item, index) => (
                    <div className="accordion-item" key={index}>
                      <h2 className="accordion-header">
                        <button
                          className={`accordion-button collapsed ${
                            item.Status === 0 ? "unread" : ""
                          }`}
                          type="button"
                          onClick={() => handleMarkAsRead(item.Id)}
                          data-bs-toggle="collapse"
                          data-bs-target={`#flush-collapse-${index}`}
                          aria-expanded="false"
                          aria-controls={`flush-collapse-${index}`}
                        >
                          <span>{item.Subject}</span>
                          <span>{item.created_at}</span>
                        </button>
                      </h2>
                      <div
                        id={`flush-collapse-${index}`}
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">{item.Message}</div>
                      </div>
                    </div>
                  )):<p className="card-title" style={{ fontSize: "20px" }}>No Messages Found</p>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Inbox;
