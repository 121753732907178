import React from "react";
import { Link } from "react-router-dom";

function AboutSection(props) {
  const display = props.display;
  return (
    <>
      <div className="container my-20">
        <div className="row">
          <div className="col-md-6 pt-3">
            <small className="text-uppercase text-color-sm">
              {props.secTitle}
            </small>
            <h1 className="mb-4 text-color-lg">{props.subHeading}</h1>
            <p className="my-6 text-color-sm">{props.content}</p>
            <Link to="/About">
              <button type="button" className={`btn btn-lg  my-3 ` + display}>
                Learn more
              </button>
            </Link>
          </div>
          <div className="col-md-6 text-center">
            {props.Img && (
              <img
                src={props.Img}
                alt=""
                className="w-100 rounded-3"
                style={{ width: "100%" }}
              />
            )}
            {/* <div className="row">
              <div className="col-md-6 mb-3">
                <img
                  src="https://images.pexels.com/photos/3184433/pexels-photo-3184433.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                  className="w-100 rounded"
                  alt=""
                />
              </div>
              <div className="col-md-6 mb-3">
                <img
                  src="https://images.pexels.com/photos/3184431/pexels-photo-3184431.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                  className="w-100 rounded"
                  alt=""
                />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutSection;
