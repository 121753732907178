import React from "react";

function BlogCard(props) {
  return (
    <>
      <div
        className="card mb-5  border-none blog-card"
        style={{ maxWidth: "580px" }}
      >
        <div className="row g-0">
          <div
            className="col-md-5 rounded-1 BlogCard-header"
            style={{ height: "200px" }}
          >
            <img
              src={`https://practicoit.in/Server/WebContent/${props.imgUrl}`}
              alt="BlogImage"
              className="h-100 rounded-1"
            />
          </div>
          <div className="col-md-7" style={{ height: "200px" }}>
            <div className="card-body py-0">
              <h3 className="card-title blog-card-title m-1">
                {props.title}...
              </h3>
              <p className="card-text m-2 d-flex justify-content-between text-color-Exdark">
                <small>{props.createdAt}</small>
                {/* <small>No Comments</small> */}
              </p>
              <p className="card-text" style={{ fontSize: "15px" }}>
                {props.content} ...
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogCard;
