import React, { useRef, useState, useEffect } from "react";
import "./MultiStepForm.css"; // CSS file
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import InterviewSchedule from "./InterviewSchedule";
import Select from "react-select";

// function MultiStepForm
function MultiStepForm() {
  const [step, setStep] = useState(1);
  const formRef = useRef(null);
  const form = formRef.current;
  const [loginEmail, setLoginEmail] = useState("");
  const [username, setUsername] = useState("");
  const [InternId, setInternId] = useState("");
  const [generatedInternId, setGeneratedInternId] = useState("");
  const [errors, setErrors] = useState({
    serverError: "",
  });
  const [selectedPlan, setSelectedPlan] = useState(""); // State to store selected plan

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    internship: "",
    course: "",
    phone: "",
    state: "",
    collegeName: "",
    selectedPlan: "",
  });

  useEffect(() => {
    if (loginEmail !== "") {
      getuserdata(loginEmail);
    }
  }, [loginEmail]);

  const getuserdata = async (loginEmail) => {
    try {
      const response = await axios.post(
        "https://practicoit.in/Server/InternshipApplication/generateInternId.php/generateInternId",
        { loginEmail: loginEmail }
      );
      // Generate internid
      const companyAbbreviation = "PIT"; // Replace with actual company abbreviation
      const positionAbbreviation = "IN"; // Replace with actual position abbreviation
      const employeeNumber =
        response.data.totalInterns !== undefined
          ? parseInt(response.data.totalInterns) + 1
          : 1;
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");

      const internid = `${companyAbbreviation}-${positionAbbreviation}-${employeeNumber}-${year}${month}${day}`;
      setGeneratedInternId(internid);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  // current Data and time
  const currentDate = new Date();
  const offset = currentDate.getTimezoneOffset(); // Get timezone offset in minutes
  currentDate.setMinutes(currentDate.getMinutes() - offset); // Adjust for timezone offset

  // Format the date and time as a MySQL-compatible string
  const formatedDate = currentDate.toISOString().slice(0, 19).replace("T", " ");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      internid: generatedInternId,
      email: loginEmail,
      username: username,
      appliedat: formatedDate,
      [name]: value,
    });
  };

  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan);
    // Update selected plan in form data
    setFormData({ ...formData, selectedPlan: plan });
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      const decodedToken = jwtDecode(token);
      // console.log("decodedToken:", decodedToken);
      setLoginEmail(decodedToken.email);
      setUsername(decodedToken.username);
      setInternId(decodedToken.internid);
    }
  }, []);
  async function handleSubmit(e) {
    e.preventDefault();
    // event.stopPropagation();
    if (form.checkValidity() === true) {
      try {
        const response = await axios.post(
          "https://practicoit.in/Server/InternshipApplication/insert.php/internship/save",
          formData
        );
        if (response.data.status === 0) {
          setErrors({
            serverError: response.data.error,
          });
        } else {
          console.log(response.data);
          const decodedToken = jwtDecode(response.data.token);
          console.log(decodedToken);
          localStorage.removeItem("token");
          localStorage.setItem("token", response.data.token);
          window.location.reload();
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    } else {
      // Form is invalid, do nothing as error messages will be displayed
    }
  }

  const handleReset = () => {
    // Reset form fields to initial state
    setFormData({
      internid: "",
      firstName: "",
      lastName: "",
      gender: "",
      internship: "",
      course: "",
      email: "",
      phone: "",
      state: "",
      collegeName: "",
      selectedPlan: "",
    });
    setStep(1);
  };
  const nextStep = () => {
    if (step === 2 && formData.selectedPlan === "") {
      form.classList.add("was-validated");
    }
    if (form.checkValidity() === false) {
      form.classList.add("was-validated");
      return;
    }
    setStep(step + 1);
    window.scrollTo(400, 400);
  };

  const prevStep = () => {
    setStep(step - 1);
    window.scrollTo(400, 400);
  };

  // ####### state option #######

  const statesOptions = [
    { value: "Andhra Pradesh", label: "Andhra Pradesh" },
    { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
    { value: "Assam", label: "Assam" },
    { value: "Bihar", label: "Bihar" },
    { value: "Chhattisgarh", label: "Chhattisgarh" },
    { value: "Goa", label: "Goa" },
    { value: "Gujarat", label: "Gujarat" },
    { value: "Haryana", label: "Haryana" },
    { value: "Himachal Pradesh", label: "Himachal Pradesh" },
    { value: "Jharkhand", label: "Jharkhand" },
    { value: "Karnataka", label: "Karnataka" },
    { value: "Kerala", label: "Kerala" },
    { value: "Madhya Pradesh", label: "Madhya Pradesh" },
    { value: "Maharashtra", label: "Maharashtra" },
    { value: "Manipur", label: "Manipur" },
    { value: "Meghalaya", label: "Meghalaya" },
    { value: "Mizoram", label: "Mizoram" },
    { value: "Nagaland", label: "Nagaland" },
    { value: "Odisha", label: "Odisha" },
    { value: "Punjab", label: "Punjab" },
    { value: "Rajasthan", label: "Rajasthan" },
    { value: "Sikkim", label: "Sikkim" },
    { value: "Tamil Nadu", label: "Tamil Nadu" },
    { value: "Telangana", label: "Telangana" },
    { value: "Tripura", label: "Tripura" },
    { value: "Uttar Pradesh", label: "Uttar Pradesh" },
    { value: "Uttarakhand", label: "Uttarakhand" },
    { value: "West Bengal", label: "West Bengal" },
    {
      value: "Andaman and Nicobar Islands",
      label: "Andaman and Nicobar Islands",
    },
    { value: "Chandigarh", label: "Chandigarh" },
    { value: "Dadra and Nagar Haveli", label: "Dadra and Nagar Haveli" },
    { value: "Daman and Diu", label: "Daman and Diu" },
    { value: "Lakshadweep", label: "Lakshadweep" },
    { value: "Delhi", label: "Delhi" },
    { value: "Puducherry", label: "Puducherry" },
    { value: "Ladakh", label: "Ladakh" },
    { value: "Jammu and Kashmir", label: "Jammu and Kashmir" },
  ];

  const coursesOptions = [
    { value: "BCA", label: "BCA" },
    { value: "B.Tech", label: "B.Tech" },
    { value: "MCA", label: "MCA" },
    { value: "M.Tech", label: "M.Tech" },
    { value: "BSc (Computer Science)", label: "BSc (Computer Science)" },
    { value: "MSc (Computer Science)", label: "MSc (Computer Science)" },
    { value: "Other", label: "Other" },
  ];

  const handleSelectChange = (selectedOption, actionMeta) => {
    handleChange({
      target: {
        name: actionMeta.name,
        value: selectedOption ? selectedOption.value : "",
      },
    });
  };

  return (
    <>
      {InternId ? (
        <InterviewSchedule />
      ) : (
        <div className="multisteps-form">
          <h1>{InternId}</h1>
          <div className="multisteps-form__progress">
            <button
              className="multisteps-form__progress-btn js-active "
              type="button"
              title="User Info"
            >
              Apply For Internship
            </button>
            <button
              className={`multisteps-form__progress-btn ${
                step >= 2 ? "js-active" : ""
              }`}
              type="button"
              title="Address"
            >
              Select Plan
            </button>
            <button
              className={`multisteps-form__progress-btn ${
                step === 3 ? "js-active" : ""
              }`}
              type="button"
              title="Comments"
            >
              Recheck
            </button>
          </div>

          <div className="my-20 shadow w-75 mx-auto ContactContainer internship-form">
            <form
              ref={formRef}
              className="row g-10"
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              {step === 1 && (
                <>
                  <div className="col-md-6 d-none">
                    <label
                      htmlFor="validationCustomInternId"
                      className="form-label"
                    >
                      Intern ID<span style={{ color: "red" }}> * </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustomInternId"
                      name="internid"
                      value={generatedInternId}
                      onChange={handleChange}
                      readOnly
                      required
                    />
                    <div className="invalid-feedback">
                      Please provide an intern ID.
                    </div>
                  </div>

                  {/* First Name */}
                  <div className="col-md-6">
                    <label htmlFor="validationCustom01" className="form-label">
                      First name<span style={{ color: "red" }}> * </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustom01"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      placeholder="First name"
                      required
                    />
                  </div>

                  {/* Last Name */}
                  <div className="col-md-6">
                    <label htmlFor="validationCustom02" className="form-label">
                      Last name<span style={{ color: "red" }}> * </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustom02"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      placeholder="Last name"
                      required
                    />
                  </div>

                  {/* Gender */}
                  <div className="col-md-5">
                    <label
                      htmlFor="validationCustomGender"
                      className="form-label"
                    >
                      Gender<span style={{ color: "red" }}> * </span>
                    </label>
                    <div className="d-flex">
                      {/* Gender radio buttons */}
                      <div className="form-check mx-2">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="validationCustomGenderMale"
                          name="gender"
                          value="male"
                          checked={formData.gender === "male"}
                          onChange={handleChange}
                          required
                        />
                        <label
                          htmlFor="validationCustomGenderMale"
                          className="form-check-label"
                        >
                          Male
                        </label>
                      </div>
                      <div className="form-check mx-2">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="validationCustomGenderFemale"
                          name="gender"
                          value="female"
                          checked={formData.gender === "female"}
                          onChange={handleChange}
                          required
                        />
                        <label
                          htmlFor="validationCustomGenderFemale"
                          className="form-check-label"
                        >
                          Female
                        </label>
                      </div>
                      <div className="form-check mx-2">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="validationCustomGenderOthers"
                          name="gender"
                          value="Others"
                          checked={formData.gender === "Others"}
                          onChange={handleChange}
                          required
                        />
                        <label
                          htmlFor="validationCustomGenderOthers"
                          className="form-check-label"
                        >
                          Others
                        </label>
                      </div>
                    </div>
                    <div className="invalid-feedback">
                      Please select your gender.
                    </div>
                  </div>

                  {/* Internship */}
                  <div className="col-md-6">
                    <label className="form-label">
                      Have you enrolled in any other internship before?
                      <span style={{ color: "red" }}> * </span>
                    </label>
                    <div className="d-flex">
                      {/* Radio buttons for internship */}
                      <div className="form-check mx-2">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="yes"
                          name="internship"
                          value="yes"
                          checked={formData.internship === "yes"}
                          onChange={handleChange}
                          required
                        />
                        <label htmlFor="yes" className="form-check-label">
                          Yes
                        </label>
                      </div>
                      <div className="form-check mx-2">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="no"
                          name="internship"
                          value="no"
                          checked={formData.internship === "no"}
                          onChange={handleChange}
                          required
                        />
                        <label htmlFor="no" className="form-check-label">
                          No
                        </label>
                      </div>
                    </div>
                    <div className="invalid-feedback">
                      Please select an option.
                    </div>
                  </div>

                  {/* Course */}
                  <div className="col-md-6">
                    <label
                      htmlFor="validationCustomCourse"
                      className="form-label"
                    >
                      Course Name<span style={{ color: "red" }}> * </span>
                    </label>
                    <Select
                      id="validationCustomCourse"
                      name="course"
                      options={coursesOptions}
                      value={coursesOptions.find(
                        (option) => option.value === formData.course
                      )}
                      onChange={handleSelectChange}
                      placeholder="Select Course"
                      className="form-selection"
                      classNamePrefix="select"
                      required
                    />
                    <div className="invalid-feedback">
                      Please select a course.
                    </div>
                  </div>

                  {/* Email */}
                  <div className="col-md-6">
                    <label
                      htmlFor="validationCustomEmail"
                      className="form-label"
                    >
                      Email<span style={{ color: "red" }}> * </span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="validationCustomEmail"
                      name="email"
                      value={loginEmail}
                      readOnly
                      required
                    />
                    <div className="invalid-feedback">
                      Please provide a valid email address.
                    </div>
                  </div>

                  {/* Phone number */}
                  <div className="col-md-6">
                    <label
                      htmlFor="validationCustomPhone"
                      className="form-label"
                    >
                      Phone number<span style={{ color: "red" }}> * </span>
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                      id="validationCustomPhone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                      placeholder="1234567890"
                      required
                    />
                    <div className="invalid-feedback">
                      Please provide a valid phone number (e.g., 1234567890).
                    </div>
                  </div>

                  {/* State */}
                  <div className="col-md-6">
                    <label htmlFor="validationCustom05" className="form-label">
                      State<span style={{ color: "red" }}> * </span>
                    </label>
                    <Select
                      id="validationCustom05"
                      name="state"
                      options={statesOptions}
                      value={statesOptions.find(
                        (option) => option.value === formData.state
                      )}
                      onChange={handleSelectChange}
                      placeholder="Select State"
                      className="form-selection"
                      classNamePrefix="select"
                      required
                    />
                    <div className="invalid-feedback">
                      Please provide State name
                    </div>
                  </div>

                  {/* College Name */}
                  <div className="col-md-12">
                    <label htmlFor="validationCustom05" className="form-label">
                      COLLEGE NAME (Full Name)
                      <span style={{ color: "red" }}> * </span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustom05"
                      name="collegeName"
                      value={formData.collegeName}
                      onChange={handleChange}
                      placeholder="Enter College Name"
                      required
                    />
                    <div className="invalid-feedback">
                      Please provide complete college name{" "}
                    </div>
                  </div>

                  {/* Terms and conditions */}
                  <div className="col-12">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="invalidCheck"
                        required
                      />
                      <label
                        className="form-check-label"
                        htmlFor="invalidCheck"
                      >
                        Agree to terms and conditions
                      </label>
                      <div className="invalid-feedback">
                        You must agree before submitting.
                      </div>
                    </div>
                  </div>
                </>
              )}

              {step === 2 && (
                <>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      {/* <!-- Pricing Card --> */}
                      <div className="pricing-card pricing-card1">
                        <div className="card shadow-sm border-light text-center mt-1">
                          <header
                            className={`card-header p-3  ${
                              selectedPlan === "Free"
                                ? "pricing-card-active"
                                : ""
                            }`}
                          >
                            <h2 className="h5 text-primary mb-4">Free Plan</h2>
                            <span className="d-block">
                              <span className="display-1 text-dark font-weight-bold">
                                &#8377; 0
                              </span>
                              <span className="d-block text-color-light font-small">
                                / Internship
                              </span>
                            </span>
                          </header>
                          <div
                            className={`card-body  ${
                              selectedPlan === "Free"
                                ? "pricing-card-active"
                                : ""
                            }`}
                          >
                            <ul className="list-group mb-4">
                              <li className="list-group-item">
                                Interview : Theory and Problem Solving
                              </li>
                              <li className="list-group-item">
                                Html, CSS, Javascript or Framework / Library
                              </li>
                              <li className="list-group-item">
                                Php / Django / Node.js or other
                              </li>
                              <li className="list-group-item">
                                Self made project
                              </li>
                              <li className="list-group-item">
                                Internship with Mentor Guidence
                              </li>
                              <li className="list-group-item">
                                30 Days Internship
                              </li>
                            </ul>
                            <input
                              type="button"
                              className={`btn btn-primary btn-block animate-up-1 ${
                                formData.selectedPlan === "Free"
                                  ? "selected"
                                  : ""
                              }`}
                              tabIndex={0}
                              value={`${
                                formData.selectedPlan === "Free"
                                  ? "Selected"
                                  : "Select"
                              }`}
                              onClick={() => handleSelectPlan("Free")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 ">
                      {/* <!-- Pricing Card --> */}
                      <div className="pricing-card pricing-card2">
                        <div className="card shadow-sm border-light text-center mt-1">
                          <header
                            className={`card-header p-3  ${
                              selectedPlan === "Charged"
                                ? "pricing-card-active"
                                : ""
                            }`}
                          >
                            <h2 className="h5 text-primary mb-4">
                              Premium Plan
                            </h2>
                            <span className="d-block">
                              <span className="display-1 text-dark font-weight-bold">
                                <span className="align-top font-medium">
                                  &#8377;
                                </span>
                                250
                              </span>
                              <span className="d-block text-color-light font-small">
                                / Internship
                              </span>
                            </span>
                          </header>
                          <div
                            className={`card-body  ${
                              selectedPlan === "Charged"
                                ? "pricing-card-active"
                                : ""
                            }`}
                          >
                            <ul className="list-group mb-4">
                              <li className="list-group-item">
                                No Interview Required
                              </li>
                              <li className="list-group-item">
                                Basic Knowledge of HTML, CSS, Javascript
                              </li>
                              <li className="list-group-item">
                                Basic Knowledge of Php
                              </li>
                              <li className="list-group-item">
                                Basic Project (Not Compulsary)
                              </li>
                              <li className="list-group-item">
                                Internship with Mentor Guidence
                              </li>
                              <li className="list-group-item">
                                15 Days training and 30 Days Internship
                              </li>
                            </ul>
                            <input
                              type="button"
                              className={`btn btn-secondary btn-block animate-up-1 ${
                                formData.selectedPlan === "Premium"
                                  ? "selected"
                                  : ""
                              }`}
                              tabIndex={0}
                              value={`${
                                formData.selectedPlan === "Premium"
                                  ? "Selected"
                                  : "Select"
                              }`}
                              onClick={() => handleSelectPlan("Premium")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {step === 3 && (
                <div className="form-details">
                  <h2>Check Your Details Are Correct Or Not</h2>
                  <strong>InternId = {formData.internid}</strong>
                  <strong>
                    Name = {formData.firstName} {formData.lastName}
                  </strong>
                  <strong>Gender = {formData.gender}</strong>
                  <strong>
                    Have you enrolled in any other internship before? ={" "}
                    {formData.internship}
                  </strong>
                  <strong>
                    course = {formData.course} {}
                  </strong>
                  <strong>email = {formData.email}</strong>
                  <strong>phone = {formData.phone}</strong>
                  <strong>state = {formData.state}</strong>
                  <strong>collegeName = {formData.collegeName}</strong>
                  <strong>Selected Plan = {formData.selectedPlan}</strong>
                </div>
              )}
              {/* Error Message */}
              {errors.serverError !== "" && (
                <div>
                  <div
                    className="alert alert-danger alert-dismissible fade show"
                    role="alert"
                  >
                    <strong>Error!</strong> {errors.serverError}
                  </div>
                </div>
              )}
              <div className="button-row d-flex mt-4 col-12">
                {step !== 1 ? (
                  <input
                    className="btn btn-primary js-btn-prev"
                    type="button"
                    title="Prev"
                    onClick={prevStep}
                    value={"Prev"}
                  />
                ) : (
                  <input
                    className="btn btn-primary js-btn-prev"
                    type="button"
                    title="Reset"
                    onClick={handleReset}
                    value={"Reset"}
                  />
                )}
                {step !== 3 ? (
                  <input
                    className="btn btn-success ml-auto js-btn-next"
                    type="button"
                    title="Next"
                    onClick={nextStep}
                    value={"Next"}
                    disabled={step === 2 && formData.selectedPlan === ""}
                  />
                ) : (
                  <button
                    className="btn btn-success ml-auto submit-btn"
                    type="submit"
                    title="Send"
                  >
                    Send
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default MultiStepForm;
