import React, { useState, useEffect } from "react";
import "../components/SignUp.css";
import SignIn from "../components/SignIn";
import SignUp from "../components/SignUp";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function Sign() {
  const [isSignUp, setIsSignUp] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  const handleSignUp = () => {
    setIsSignUp(true);
  };

  const handleSignIn = () => {
    setIsSignUp(false);
  };

  return (
    <>
      <Navbar />
      <div className={`sign_container ${isSignUp ? "sign-up-mode" : ""}`}>
        <div className="forms-sign_container">
          <div className="signin-signup">
            {/* Sign in form */}
            <SignIn />
            {/* Sign up form */}
            <SignUp />
          </div>
        </div>
        {/* panels container */}
        <div className="panels-sign_container">
          <div className="panel left-panel">
            <div className="content">
              <h3>New to our community ?</h3>
              <p>
                Discover a world of possibilities! Join us and explore a vibrant
                community where ideas flourish and connections thrive.
              </p>
              <button
                className="btn transparent sign-btn"
                onClick={handleSignUp}
              >
                Sign up
              </button>
            </div>
            <img
              src="https://i.ibb.co/6HXL6q1/Privacy-policy-rafiki.png"
              className="image"
              alt=""
            />
          </div>
          <div className="panel right-panel">
            <div className="content">
              <h3>One of Our Valued Members</h3>
              <p>
                Thank you for being part of our community. Your presence
                enriches our shared experiences. Let's continue this journey
                together!
              </p>
              <button
                className="btn transparent sign-btn"
                onClick={handleSignIn}
              >
                Sign in
              </button>
            </div>
            <img
              src="https://i.ibb.co/nP8H853/Mobile-login-rafiki.png"
              className="image"
              alt=""
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Sign;
